import React, { ReactElement } from 'react';

import s from './BlogArticleCard.module.scss';
import { Blog, blogCategories } from './Blogs';
import TrackableLink from '../TrackableLink';

interface BlogArticleCardProps {
  blog: Blog;
}

const BlogArticleCard = ({ blog }: BlogArticleCardProps): ReactElement => {
  const {
    category,
    src,
    alt,
    link,
    linkTitle,
    ariaLabel,
    subTitle,
    timeTitle,
    dateTime,
  } = blog;

  return (
    <div className={s.wrapper}>
      <TrackableLink
        to={link}
        className={s.link}
        title={linkTitle}
        aria-label={ariaLabel}
      >
        <div>
          <div className={s.imageContainer}>
            <img src={src} alt={alt} />
          </div>
          <div className={s.contentContainer}>
            <h2>
              <a href="https://www.neubauprojekte.wien/altbau-oder-neubau/">
                {linkTitle}
              </a>
            </h2>
            <time dateTime={dateTime} title={timeTitle}>
              {subTitle}
            </time>
          </div>
        </div>
      </TrackableLink>
      <div className={s.categoryButton}>
        <a
          className="w-btn us-btn-style_5"
          href={`/category/${
            blogCategories.find((c) => c.id === category).link
          }/`}
        >
          <span className="w-btn-label">
            {blogCategories.find((c) => c.id === category).title}
          </span>
          <span className="ripple-container" />
        </a>
      </div>
    </div>
  );
};

export default BlogArticleCard;

/* eslint-disable no-shadow */
enum BlogCategories {
  GefoerderteWohnungen,
  Immobilienmarkt,
  RatgeberEigentumswohnung,
}

enum BlogId {
  AltbauNeubau,
  RatgeberEigentumswohnung,
  Immobilienpreise,
  NebenkostenWohnungskauf,
  GefoerderteWohnungen,
}

export interface Blog {
  id: BlogId;
  category: BlogCategories;
  src: string;
  alt: string;
  link: string;
  linkTitle: string;
  ariaLabel: string;
  subTitle: string;
  timeTitle: string;
  dateTime: string;
}

interface BlogCategory {
  id: BlogCategories;
  title: string;
  link: string;
}

const blogCategories: BlogCategory[] = [
  {
    id: BlogCategories.GefoerderteWohnungen,
    title: 'Geförderte Wohnungen',
    link: 'gefoerderte-wohnung',
  },
  {
    id: BlogCategories.Immobilienmarkt,
    title: 'Immobilienmarkt',
    link: 'immobilienmarkt',
  },
  {
    id: BlogCategories.RatgeberEigentumswohnung,
    title: 'Ratgeber Eigentumswohnung',
    link: 'ratgeber-eigentumswohnung',
  },
];

const blogs: Blog[] = [
  {
    id: BlogId.AltbauNeubau,
    category: BlogCategories.RatgeberEigentumswohnung,
    src: '/blog/altbau-oder-neubau.jpg',
    alt: 'altbau-oder-neubau',
    link: '/altbau-oder-neubau',
    linkTitle: 'Altbau oder Neubau?',
    ariaLabel: 'Altbau oder Neubau?',
    subTitle: '13 Aug 2020',
    timeTitle: '13 August 2020 um 09:00:50 Europe/Vienna&nbsp;Uhr',
    dateTime: '2020-08-13T09:00:50+02:00',
  },
  {
    id: BlogId.RatgeberEigentumswohnung,
    category: BlogCategories.RatgeberEigentumswohnung,
    src: '/blog/vorsorgewohnung-wien.jpg',
    alt: 'vorsorgewohnung-wien',
    link: '/vorsorgewohnung-wien',
    linkTitle: 'Vorsorgewohnung Wien 2020',
    ariaLabel: 'Vorsorgewohnung Wien 2020',
    subTitle: '14 Jul 2020',
    timeTitle: '14 Juli 2020 um 12:05:09 Europe/Vienna Uhr',
    dateTime: '2020-07-14T12:05:09+02:00',
  },
  {
    id: BlogId.Immobilienpreise,
    category: BlogCategories.Immobilienmarkt,
    src: '/blog/immobilienpreise-wohnung-wien.jpg',
    alt: 'immobilienpreise wohnung wien',
    link: '/immobilienpreise-wien-2020',
    linkTitle: 'Immobilienpreise Wien 2020',
    ariaLabel: 'Immobilienpreise Wien 2020',
    subTitle: '20 Jun 2020',
    timeTitle: '20 Juni 2020 um 12:09:21 Europe/Vienna Uhr',
    dateTime: '2020-06-20T12:09:21+02:00',
  },
  {
    id: BlogId.NebenkostenWohnungskauf,
    category: BlogCategories.RatgeberEigentumswohnung,
    src: '/blog/nebenkosten-wohnungskauf.jpg',
    alt: 'nebenkosten wohnungskauf',
    link: '/nebenkosten-wohnungskauf/',
    linkTitle: 'Nebenkosten Wohnungskauf',
    ariaLabel: 'Nebenkosten Wohnungskauf',
    subTitle: '20 Jun 2020',
    timeTitle: '20 Juni 2020 um 12:00:16 Europe/Vienna Uhr',
    dateTime: '2020-06-20T12:00:16+02:00',
  },
  {
    id: BlogId.GefoerderteWohnungen,
    category: BlogCategories.GefoerderteWohnungen,
    src: '/blog/gefoerderte-wohnung-wien.jpg',
    alt: 'gefoerderte wohnung wien',
    link: '/gefoerderte-wohnungen-wien',
    linkTitle: 'Geförderte Wohnungen in Wien',
    ariaLabel: 'Geförderte Wohnungen in Wien',
    subTitle: '20 Jun 2020',
    timeTitle: '20 Juni 2020 um 11:58:03 Europe/Vienna Uhr',
    dateTime: '2020-06-20T11:58:03+02:00',
  },
];

export { BlogCategories, BlogId, blogs, blogCategories };

import React, { ReactElement } from 'react';
import { Helmet } from 'react-helmet';

import LinkButton from '../../Buttons/LinkButton';

import Block from '../../Layout/Block';
import { BlogCategories, BlogId } from '../Blogs';

import './Article.scss';
import ArticleBreadcrumbs from './ArticleBreadcrumbs';
import ArticleContents from './ArticleContents';
import ArticleSidebar from './ArticleSidebar';
import usePageTracking from '../../../hooks/usePageTracking';
import TrackableLink from '../../TrackableLink';

const GefoerderteWohnungenArticle = (): ReactElement => {
  const firstParagraph =
    'Geförderte Wohnungen sind und bleiben begehrte Wohnobjekte. In diesem Aritkel erfahren Sie alles was Sie über geförderte Wohnungen wissen sollten.';

  usePageTracking(
    'Geförderte Wohnungen in Wien - Was ist eine geförderte Wohnung?'
  );

  return (
    <>
      <Helmet>
        <title>
          Geförderte Wohnungen in Wien - Was ist eine geförderte Wohnung?
        </title>
        <meta name="description" content={firstParagraph} />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Geförderte Wohnungen in Wien - Was ist eine geförderte Wohnung?"
        />
        <meta property="og:description" content={firstParagraph} />
        <meta
          property="og:url"
          content="https://www.neubauprojekte.wien/gefoerderte-wohnungen-wien/"
        />
        <meta
          property="article:published_time"
          content="2020-06-20T09:58:03+00:00"
        />
        <meta
          property="article:modified_time"
          content="2020-12-08T16:05:54+00:00"
        />
      </Helmet>
      <div className="mainContent page article">
        <div className="p-grid">
          <div className="lg:col-8">
            <div className="article-content">
              <ArticleBreadcrumbs
                category={BlogCategories.GefoerderteWohnungen}
              />
              <h1 className="firstHeader">Nebenkosten Wohnungskauf</h1>
              <div className="separator" />
              <Block>
                <p>{firstParagraph}</p>
              </Block>
              <div style={{ width: 'fit-content' }}>
                <LinkButton
                  text="ALLE GENOSSENSCHAFTSWOHNUNGEN ANZEIGEN"
                  link="https://www.geförderte-wohnungen.at"
                />
              </div>
              <div className="separator" />
              <ArticleContents
                content={[
                  'Was ist eine geförderte Wohnung?',
                  'Geförderte Wohnung mieten',
                  'Geförderte Wohnung kaufen',
                  'So bewerben Sie sich für eine geförderte Wohnung',
                  'Vergleich geförderte Wohnung und Gemeindebauwohnung',
                ]}
              />
              <div className="separator" />
              <h2 id="1">Was ist eine geförderte Wohnung?</h2>
              <Block>
                Geförderte Wohnungen (oft auch „Genossenschaftswohnungen“
                genannt) sind und bleiben begehrte Wohnobjekte, die nicht nur
                helfen steigenden Mietpreisen zu entgehen, sondern für viele
                WienerInnen auch den ersten Schritt zum Traum vom Eigenheim
                darstellen. Sie werden von gemeinnützigen Bauvereinigungen mit
                Fördermittel der Stadt Wien errichtet. Die üblicherweise
                besonders fairen Mietpreise werden dadurch erreicht, dass
                einerseits Baukosten durch Inanspruchnahme staatlicher
                Förderungen niedrig gehalten werden können und es andererseits
                Beschränkungen bei der Gewinnerzielung für diese Objekte gibt.
              </Block>

              <h2 id="2">
                Voraussetzungen für die Miete einer geförderten Wohnung
              </h2>
              <Block>
                Um sich für die Miete bzw. den Kauf einer geförderten Wohnungen
                bewerben zu können, müssen bestimmte{' '}
                <a
                  href="https://wohnberatung-wien.at/wiener-wohn-ticket/allgemeines"
                  target="_blank"
                  rel="noreferrer"
                >
                  Voraussetzungen
                </a>{' '}
                nach den Kriterien des WWFSG (Wiener Wohnbauförderungs- und
                Wohnhaussanierungsgesetz 1989) erfüllt sein:
                <div className="list-wrapper">
                  <ul>
                    <li>
                      Das Mindestalter beträgt für eine Anmeldung 17 Jahre, der
                      Bezug der Wohnung ist ab mindestens 18 Jahren möglich.
                    </li>
                    <li>
                      Der Bewerber muss eine österreichische oder der
                      österreichischen gleichgestellten (=EU/EWR)
                      Staatsbürgerschaft besitzen.
                    </li>
                    <li>
                      Soll eine Wohnung in Wien bezogen werden, muss der
                      Interessent davor mindestens 2 Jahre lang an der aktuellen
                      Wiener Adresse gemeldet gewesen sein. Ausnahme sind
                      JungwienerInnen, die zwischen 17 und 30 Jahre alt sind und
                      ihren Hauptwohnsitz seit über 10 Jahren bei Ihren Eltern
                      haben.
                    </li>
                    <li>
                      Es muss ein dringender Wohnbedarf vorliegen (siehe § 11
                      (1) Z1).
                    </li>
                    <li>
                      Die folgenden Einkommenshöchstgrenzen für das
                      Nettojahreseinkommen des Haushalts (gem. § 11., Abs.2)
                      dürfen nicht überschritten werden:
                    </li>
                  </ul>
                </div>
                <table>
                  <tbody>
                    <tr>
                      <td width="321">
                        <strong>Personenanzahl</strong>
                      </td>
                      <td width="321">
                        <strong>Gehaltsobergrenze</strong>
                      </td>
                    </tr>
                    <tr>
                      <td width="321">1 Person</td>
                      <td width="321">€ 47.210</td>
                    </tr>
                    <tr>
                      <td width="321">2 Personen</td>
                      <td width="321">€ 70.340</td>
                    </tr>
                    <tr>
                      <td width="321">3 Personen</td>
                      <td width="321">€ 79.600</td>
                    </tr>
                    <tr>
                      <td width="321">4 Personen</td>
                      <td width="321">€ 88.860</td>
                    </tr>
                    <tr>
                      <td width="321">jede weitere Person</td>
                      <td width="321">+€ 5.180</td>
                    </tr>
                  </tbody>
                </table>
                <p>
                  Das Einkommen wird bei einem vorliegenden
                  Angestelltenverhältnis über die Jahreslohnzettel des Vorjahres
                  und die Gehaltszettel der letzten 3 Monate beurteilt. Bei
                  einer selbstständigen Tätigkeit zählt der
                  Einkommenssteuerbescheid des letzten veranlagten
                  Kalenderjahres.
                </p>
              </Block>
              <h2 id="3">
                Voraussetzungen für den Kauf einer geförderten Wohnung
              </h2>

              <p>
                Um Eigentum an einer geförderten Wohnung zu bekommen gibt es
                grundsätzlich zwei Möglichkeiten:
              </p>
              <Block>
                <div className="list-wrapper">
                  <ul>
                    <li>Miete einer geförderten Wohnung mit Kaufoption</li>
                    <li>Kauf einer geförderten Eigentumswohnung</li>
                  </ul>
                </div>
                <p>
                  Im ersten Fall gelten dieselben Bedingungen, die generell zum
                  Abschluss einer geförderten Wohnung notwendig sind. Die
                  Kaufoption erlaubt es Mietern die geförderte Wohnung nach 10
                  bis 15 Jahren zu marktüblichen Preisen zu erwerben. Beim
                  Vertragsabschluss bezahlte Finanzierungsbeiträge (siehe § 8
                  WWFSG) werden dabei abzüglich einer jährlichen Abschreibung
                  iHv. 1% auf den Kaufpreis angerechnet.
                </p>
                <p>
                  Soll eine geförderte Eigentumswohnung erworben werden, gelten
                  grundsätzlich dieselben Bedingungen wie für einen Mietvertrag,
                  die Einkommenshöchstgrenzen erhöht sich jedoch auf von
                  53.950,00 Euro für eine Person bis zu 101.550,00 Euro für 4
                  Personen, sowie 5.920 Euro zusätzlich für jede weitere Person.
                  Beim Kauf der Wohnung wird ein Teil des Kaufpreises sofort
                  fällig, der Rest des Betrages ist als gefördertes Darlehen
                  über einen längeren Zeitraum abzuzahlen.
                </p>
              </Block>

              <h2 id="4">
                Wie bewerbe ich mich für eine geförderte Wohnung in Wien?
              </h2>
              <Block>
                Um sich für eine dieser geförderten Wohnungen zu bewerben kommen
                drei Möglichkeiten in Frage:
                <div className="list-wrapper">
                  <ul>
                    <li>
                      Für neue Wohnprojekte sowie Wohnungen, die vom
                      ursprünglichen Mieter an die Genossenschaft zurückgegeben
                      wurden (Zweitvergaben) kann man üblicherweise bei den
                      Genossenschaften direkt sein Interesse bekunden. Nach
                      einem „first-come, first-serve“ Prinzip werden rechtliche
                      und finanzielle Voraussetzungen für die Miete oder den
                      Kauf der geförderten Wohnung geprüft, bevor die
                      Genossenschaft ein Vertragsangebot legt.
                      <br />
                      <TrackableLink to="/genossenschaften">
                        {'>'} Hier finden Sie eine Übersicht aller
                        Genossenschaften in Wien.
                      </TrackableLink>
                    </li>
                    <li>
                      Um einen Überblick über geförderte Neubauprojekte
                      unterschiedlicher Genossenschaften zu bekommen, bietet
                      sich vor allem das Online-Portal der Wohnberatung Wien an.
                      Nach einer kurzen Registrierung kann ein Wiener
                      Wohn-Ticket beantragt werden mit dem eine schnelle
                      Bewerbung möglich ist.
                      <br />
                      <TrackableLink to="/?planung_bau=planing">
                        {'>'} Hier können Sie alle Neubauprojekte einsehen, die
                        sich derzeit in Planung befinden.
                      </TrackableLink>
                    </li>
                    <li>
                      Auch die Übernahme einer Wohnung von einer Privatperson
                      ist möglich. Mieter von geförderten Wohnungen haben oft
                      ein Weitergabe- oder Vorschlagsrecht für den Nachmieter.
                      In diesem Fall ist aber besondere Vorsicht geboten,
                      einerseits sind mit Privatweitergaben oft sehr hohe
                      Ablösen für Möbel und sonstige Investitionen verbunden,
                      andererseits hat die Genossenschaft das letzte Wort.
                      Manchmal suchen Mieter auch online auf
                      Immobilienplattformen nach Nachmietern.
                      <br />
                      <a
                        href="https://www.willhaben.at/iad/immobilien/mietwohnungen/l/genossenschaftswohnung"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {'>'} Wir empfehlen Ihnen die Plattform willhaben
                        (Filter Volltextsuche „Genossenschaft“.)
                      </a>
                    </li>
                  </ul>
                </div>
              </Block>
              <h2 id="5">
                Was unterscheidet eine geförderte Wohnung von einer
                Gemeindewohnung?
              </h2>
              <Block>
                <p>
                  Auf den ersten Blick wirkt das Angebot und die Bedingungen für
                  die Bewerbung für eine geförderte Wohnungen oder
                  Gemeindewohnungen sehr ähnlich. Beispielsweise sind die Regeln
                  hinsichtlich Mindestalter, Staatsbürgerschaft, Wohnbedarf,
                  Meldung in der entsprechenden Gemeinde und
                  Einkommensobergrenzen gleich. Die Unterschiede sind in Tabelle
                  1 gegenübergestellt.
                </p>
                <p>
                  Vorteile von Gemeinde- und Sozialwohnungen liegen also primär
                  darin, dass üblicherweise kein Finanzierungsbeitrag und
                  Genossenschafts- mitgliedsbeitrag geleistet werden müssen. Im
                  Gegenzug ist der Interessent bei der Wahl der Wohnung
                  eingeschränkt und hat keine Möglichkeit die Wohnung zu einem
                  späteren Zeitpunkt zu erwerben.
                </p>
              </Block>
              <span style={{ color: '#777777' }}>
                Tabelle 1 – Geförderte Wohnung und Gemeindebauwohnung im
                Vergleich
              </span>
              <table>
                <tbody>
                  <tr>
                    <td width="120">
                      <strong>&nbsp;</strong>
                    </td>
                    <td width="230">
                      <strong>Geförderte Wohnung</strong>
                    </td>
                    <td width="230">
                      <strong>Gemeindewohnung</strong>
                    </td>
                  </tr>
                  <tr>
                    <td width="120">
                      <strong>Reihung der Interessenten</strong>
                    </td>
                    <td width="230">
                      Tendenziell „First-Come, First-Served“ außer bei Bewerbung
                      über Wiener Wohn-Ticket
                    </td>
                    <td width="230">
                      Nach Ausstellungsdatum des Wiener Wohn-Tickets
                    </td>
                  </tr>
                  <tr>
                    <td width="120">
                      <strong>Wahl des Wohnobjekts</strong>
                    </td>
                    <td width="230">
                      Interesse kann an ausgewählten Wohnungen bekundet werden,
                      keine Nachteile falls diese nach Besichtigung abgelehnt
                      wird.
                    </td>
                    <td width="230">
                      Seit Mai 2020: Wohnungen können digital begutachtet werden
                      und Kriterien abgesteckt werden. Die Gemeinde zieht diese
                      Kriterien für den Vorschlag von zwei Wohnungen zurate.
                      Nach Ablehnen beider Vorschläge kann der Interessent 3
                      Jahre lang zwar keine Gemeindewohnung suchen aber im
                      geförderten Sektor weiterhin sogenannte SMART Wohnungen
                      suchen.
                    </td>
                  </tr>
                  <tr>
                    <td width="120">
                      <strong>Wohnungsgröße</strong>
                    </td>
                    <td width="230">Frei wählbar</td>
                    <td width="230">
                      Abhängig von der Personenanzahl im Haushalt
                    </td>
                  </tr>
                  <tr>
                    <td width="120">
                      <strong>Finanzierungsbeitrag</strong>
                    </td>
                    <td width="230">Ja</td>
                    <td width="230">Nein</td>
                  </tr>
                  <tr>
                    <td width="120">
                      <strong>Mitgliedsbeitrag bei Genossenschaft</strong>
                    </td>
                    <td width="230">Ja</td>
                    <td width="230">Nein</td>
                  </tr>
                  <tr>
                    <td width="120">
                      <strong>Kaufoption</strong>
                    </td>
                    <td width="230">Teilweise</td>
                    <td width="230">Nein</td>
                  </tr>
                </tbody>
              </table>

              <h3>Weiterführende Informationen – Stand 21.06.2020</h3>
              <div>
                <p>
                  <a href="https://www.wien.gv.at/wohnen/wohnbaufoerderung/landesdarlehen/hoech">
                    https://www.wien.gv.at/wohnen/wohnbaufoerderung/landesdarlehen/hoech
                  </a>
                </p>
                <p>
                  <a href="https://wohnberatung-wien.at/">
                    https://wohnberatung-wien.at/
                  </a>
                </p>
                <p>
                  <a href="https://www.ris.bka.gv.at/GeltendeFassung.wxe?Abfrage=LrW&Gesetzesnummer=20000049">
                    https://www.ris.bka.gv.at/GeltendeFassung.wxe?Abfrage=LrW&Gesetzesnummer=20000049
                  </a>
                </p>
              </div>
            </div>
          </div>
          <ArticleSidebar blogId={BlogId.AltbauNeubau} />
        </div>
      </div>
    </>
  );
};

export default GefoerderteWohnungenArticle;

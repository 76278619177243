import React, { ReactElement } from 'react';
import { Helmet } from 'react-helmet';

import Block from '../../Layout/Block';
import { BlogCategories, BlogId } from '../Blogs';

import './Article.scss';
import ArticleBreadcrumbs from './ArticleBreadcrumbs';
import ArticleContents from './ArticleContents';
import ArticleSidebar from './ArticleSidebar';
import usePageTracking from '../../../hooks/usePageTracking';

const VorsorgewohnungWienArticle = (): ReactElement => {
  const firstParagraph =
    'Suchen Sie nach einer Vorsorgewohnung in Wien? Dann sind Sie hier genau richtig – in diesem Artikel erfahren Sie alles was Sie über Vorsorgewohnungen in Wien wissen sollten!';

  usePageTracking('Vorsorgewohnung Wien 2020 - Einnahmen und Ausgaben');

  return (
    <>
      <Helmet>
        <title>Vorsorgewohnung Wien 2020 - Einnahmen und Ausgaben</title>
        <meta name="description" content={firstParagraph} />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Vorsorgewohnung Wien 2020 - Einnahmen und Ausgaben"
        />
        <meta property="og:description" content={firstParagraph} />
        <meta
          property="og:url"
          content="https://www.neubauprojekte.wien/vorsorgewohnung-wien/"
        />
        <meta
          property="article:published_time"
          content="2020-07-14T10:05:09+00:00"
        />
        <meta
          property="article:modified_time"
          content="2020-08-16T14:23:27+00:00"
        />
      </Helmet>
      <div className="mainContent page article">
        <div className="p-grid">
          <div className="lg:col-8">
            <div className="article-content">
              <ArticleBreadcrumbs
                category={BlogCategories.RatgeberEigentumswohnung}
              />
              <h1 className="firstHeader">Vorsorgewohnung Wien</h1>
              <div className="separator" />
              <Block>{firstParagraph}</Block>
              <ArticleContents
                content={[
                  'Was ist eine Vorsorgewohnung?',
                  'Vorteile einer Vorsorgewohnung',
                  'Verwaltung einer Vorsorgewohnung',
                  'Rendite einer Vorsorgewohnung',
                  'Die perfekte Vorsorgewohnung',
                ]}
              />
              <div className="separator" />
              <h2 id="1">Was ist eine Vorsorgewohnung?</h2>
              <Block>
                Private Altersvorsorge oder risikoarme Geldanlage,
                Vorsorgewohnungen sind ein beliebtes Mittel das monatliche
                Gehalt oder die Pension durch Mieteinnahmen aufzubessern oder
                existierendes Kapital vor inflationsbedingter Wertminderung zu
                schützen. Der Zweck einer Vorsorgewohnung liegt im Gegensatz zu
                einer klassischen Eigentumswohnung nicht in der Deckung eines
                eigenen Wohnbedürfnisses, sondern ausschließlich in der
                Erzielung von Mieteinnahmen. Will der Eigentümer die
                steuerlichen Vorteile der Vorsorgewohnung nutzen ist es deswegen
                notwendig, dass diese 20 Jahre lang nicht selbst bewohnt oder
                veräußert wird, andernfalls kann es nachträglich zu hohen
                Steuernachzahlungen kommen.
              </Block>
              <h2 id="2">Worin liegen die Vorteile einer Vorsorgewohnung?</h2>
              <Block>
                <p>
                  Wird dem Finanzamt nach Kauf der Vorsorgewohnung bekannt
                  gegeben, dass diese ausschließlich zum Zweck der Erzielung von
                  Einkünften aus Vermietung und Verpachtung gem. § 28 EStG
                  genutzt werden soll, können die folgenden steuerlichen
                  Begünstigungen in Anspruch genommen werden:
                </p>
                <div className="list-wrapper">
                  <ul>
                    <li>
                      Die beim Kauf bezahlte Umsatzsteuer (20% des
                      Bruttokaufpreises) kann als Vorsteuer zurückerstattet
                      werden (vgl. § 6 Abs. 2 UStG).
                    </li>
                    <li>
                      Mieteinnahmen werden durch die jährliche Abschreibung für
                      Abnutzung (i.H.v. 1.5% des Gebäudeanteils des Kaufpreises)
                      steuerrechtlich gekürzt (vgl. §16 Abs. 1 Z 8 lit d und §2
                      Abs. 2 TS 2 GrundanteilVO)
                    </li>
                    <li>
                      Wird für den Kauf der Wohnung ein Kredit aufgekommen
                      können die in dem Zusammenhang anfallenden Zinsen
                      ebenfalls steuerrechtlich geltend gemacht werden und vom
                      Mieteinkommen abgezogen werden (vgl. § 16 Abs. 1 Z 1 EStG)
                    </li>
                  </ul>
                </div>
                <p>
                  Abgesehen von steuerlichen Vorteilen bieten Vorsorgewohnungen
                  doppelt Schutz vor inflationsbedingten Wertverlusten.
                  Einerseits sind Mieten üblicherweise an den
                  Verbraucherpreisindex angepasst und können dadurch in
                  regelmäßigen Abständen an das aktuelle Preisniveau angepasst
                  werden, und andererseits bleibt der Sachwert der Wohnung auch
                  in Krisenzeiten erhalten. Das macht sie zu einer risikoarmen
                  Alternative zu anderen Anlageformen wie Aktien oder Fonds.
                </p>
              </Block>
              <h2 id="3">
                Wer übernimmt die Verwaltung einer Vorsorgewohnung?
              </h2>
              <Block>
                <p>
                  Je nachdem wie viel Zeit in die Organisation und Vermietung
                  der Wohnung investiert werden soll, gibt es unterschiedliche
                  Modelle wie eine Vorsorgewohnung bewirtschaftet werden kann.
                  Kümmert sich der Eigentümer selbst um die Bewirtschaftung
                  fallen zwar keine weiteren Gebühren für die Verwaltung an, es
                  ist jedoch mit einem höheren laufenden Zeitaufwand zu rechnen
                  und man ist nicht vor längerfristigem Leerstand oder
                  Mietausfällen geschützt. Für Interessenten, die die notwendige
                  Zeit nicht aufbringen können bietet es sich beispielsweise an,
                  dass sich die Hausverwaltung gegen eine Verwaltungsgebühr um
                  alle Verpflichtungen, die sich aus der Vermietung ergeben
                  kümmert.
                </p>
                <p>
                  Um Zahlungsschwierigkeiten durch Mietausfälle weitgehend zu
                  vermeiden gibt es außerdem die Möglichkeit sich einem
                  „Mietenpool“ anzuschließen. Dabei werden die Mieteinnahmen
                  einiger vom externen Partner verwalteten Objekte gepoolt und
                  nach einem Verteilungsschlüssel an die Eigentümer ausgezahlt.
                  Selbst wenn die eigene Wohnung leer steht bekommt der
                  Eigentümer so weiterhin anteilige Mietzinszahlung der anderen
                  vermieteten Wohnungen.
                </p>
              </Block>
              <h2 id="4">Mit welchen Kosten/Erträgen kann man rechnen?</h2>
              <Block>
                <p style={{ paddingBottom: '0.5em' }}>
                  Vorsorgewohnungen versprechen oft Rendite, doch ob sich das
                  Modell für den Eigentümer lohnt sollte vor Anschaffung
                  idealerweise mit einem Steuerberater besprochen werden. Denn
                  sollte die Immobilie durch die Kreditzahlungen auch nach 23
                  Jahren keinen Gewinn abwerfen wird die Vermietung als
                  „Liebhaberei“ (siehe dazu §1 Abs. 2 Z 3 und § 2 Abs. 4 LiebVO)
                  betrachtet und kann steuerrechtlich nicht anerkannt werden,
                  sodass es zu saftigen Steuernachzahlungen kommen kann.
                </p>
                <p>
                  Das übliche Modell einer Vorsorgewohnung sieht vor, dass der
                  Käufer eine Eigenkapitalquote von in etwa 30% des Kaufpreises
                  inklusive Nebenkosten bereitstellt und den Restbetrag durch
                  einen Kredit finanziert. So könnten sich beispielsweise die
                  folgenden Kosten und Erträge für eine 2 Zimmer Wohnung mit 45
                  m² um 200.000 € netto inklusive Nebenkosten ergeben:
                </p>
              </Block>

              <span style={{ color: '#777777' }}>
                Tabelle 1 – Vorsorgewohnung Einnahmen-Ausgaben-Rechnung
              </span>
              <table>
                <tbody>
                  <tr>
                    <td width="231">
                      <strong>Posten</strong>
                    </td>
                    <td width="195">
                      <strong>Ausgaben</strong>
                    </td>
                    <td width="195">
                      <strong>Einnahmen</strong>
                    </td>
                  </tr>
                  <tr>
                    <td width="231">
                      <strong>
                        Abschreibung für Abnutzung<sup>1</sup>
                      </strong>
                    </td>
                    <td width="195">2.100 €</td>
                    <td width="195" />
                  </tr>
                  <tr>
                    <td width="231">
                      <strong>
                        Betriebskosten<sup>2</sup>
                      </strong>
                    </td>
                    <td width="195">1.350 €</td>
                    <td width="195" />
                  </tr>
                  <tr>
                    <td width="231">
                      <strong>Versicherungskosten</strong>
                    </td>
                    <td width="195">500 €</td>
                    <td width="195" />
                  </tr>
                  <tr>
                    <td width="231">
                      <strong>Verwaltungskosten</strong>
                    </td>
                    <td width="195">250 €</td>
                    <td width="195" />
                  </tr>
                  <tr>
                    <td width="231">
                      <strong>
                        Zinskosten<sup>3</sup>
                      </strong>
                    </td>
                    <td width="195">1.400 €</td>
                    <td width="195" />
                  </tr>
                  <tr>
                    <td width="231">
                      <strong>
                        Nettomiete<sup>4</sup>
                      </strong>
                    </td>
                    <td width="195" />
                    <td width="195">8.550 €</td>
                  </tr>
                  <tr>
                    <td width="231">
                      <strong>Weiterverrechnete Betriebskosten</strong>
                    </td>
                    <td width="195" />
                    <td width="195">1.350 €</td>
                  </tr>
                  <tr>
                    <td width="231">
                      <strong>Summe</strong>
                    </td>
                    <td width="195">5.600 €</td>
                    <td width="195">9.900 €</td>
                  </tr>
                  <tr>
                    <td width="231">
                      <strong>Saldo</strong>
                    </td>
                    <td width="195" />
                    <td width="195">4.300 €</td>
                  </tr>
                  <tr>
                    <td width="231">
                      <strong>Rendite</strong>
                    </td>
                    <td width="195" />
                    <td width="195">
                      <span style={{ color: '#339966' }}>
                        <strong>2.15 %</strong>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p>
                <sup>1 </sup>i.H.v. 1,5% vom Gebäudeanteil (=70% des
                Nettokaufpreises inklusive Nebenkosten).
                <br />
                <sup>2</sup> ca. 2,5 € netto pro m<sup>2 </sup>und Monat.
                <br />
                <sup>3</sup> Bei einer Eigenkapitalquote von 30% und einem
                effektiven Zinssatz von 1%.
                <br />
                Achtung Tilgungsanteil der Kreditrückzahlung ist nicht
                steuerwirksam.
                <br />
                <sup>4</sup> 18.50 € pro m<sup>2 </sup>und Monat.
              </p>
              <div className="spacer" />
              <h2 id="5">Wie sieht die ideale Vorsorgewohnung aus?</h2>
              <Block>
                <p>
                  Um Mietausfälle und Leerstände zu vermeiden sollten Sie bei
                  der Auswahl der perfekten Vorsorgewohnung insbesondere auf die
                  folgenden Punkte achten:
                </p>
              </Block>

              <h3>Lage & Infrastruktur</h3>
              <Block>
                <p>
                  Das wichtigste Kriterium bei der Wahl Ihrer Vorsorgewohnung
                  sollte die Lage der Immobilie sein. Das bedeutet insbesondere
                  einen guten Anschluss an öffentliche Verkehrsmittel,
                  Autobahnen und ggf. Fahrradwege aber auch, dass Geschäfte des
                  täglichen Bedarfs fußläufig erreichbar sind.
                </p>
              </Block>
              <h3>Zustand des Gebäudes</h3>
              <Block>
                <p>
                  Nicht zu unterschätzen ist auch die Beschaffenheit des
                  Gebäudes und das Angebot der Allgemeinräume wie z.B.
                  Waschküche, Fahrradraum, Fitness Center. Je älter das Gebäude
                  ist, desto höher ist auch die Wahrscheinlichkeit, dass teure
                  Renovierungsarbeiten oder die Errichtung von
                  Reparaturrücklagen zu hohen Betriebskosten führen. Im Zweifel
                  ist daher der Kauf einer Neubauwohnung (siehe auch
                  Teilanwendungsbereich des MRG) zu empfehlen.
                </p>
              </Block>
              <p />
              <h3>Größe & Raumaufteilung</h3>
              <Block>
                <p>
                  Die gefragtesten Wohnungen sind einerseits effiziente Single-
                  oder Pärchenwohnungen mit 2 Zimmern und einer Größe von ca. 45
                  m² und andererseits 3-Zimmer Wohnungen bis zu 70 m². Bei
                  Letzteren ist besonders auf eine getrennte Begehbarkeit der
                  Zimmer zu achten, sodass diese auch flexibel als WG genutzt
                  werden können. Kompakte quadratische Grundrisse sind generell
                  vorzuziehen und große Vorzimmer oder lange Gänge, die
                  üblicherweise nur schlecht genutzt werden können, gilt es zu
                  vermeiden.
                </p>
              </Block>
              <h3>Ausstattung</h3>
              <Block>
                <p>
                  Zuletzt ist auch auf die Qualität der Ausstattung zu achten.
                  Hochwertige, moderne Küchen- und Sanitäranlagen sowie
                  gepflegte Freiflächen wie Balkon oder Terrassen können den
                  Wert der Wohnung für den Mieter stark erhöhen und höhere
                  Mietpreise rechtfertigen.
                </p>
              </Block>
              <div className="spacer" />
              <h3>Quellen – Stand 15.07.2020</h3>
              <div>
                <p>
                  <a href="https://www.infina.at/ratgeber/vorsorgewohnung/">
                    https://www.infina.at/ratgeber/vorsorgewohnung/
                  </a>
                </p>
                <p>
                  <a href="https://www.rvw.at/de/Vorsorgewohnungen_Mietenpool.htm">
                    https://www.rvw.at/de/Vorsorgewohnungen_Mietenpool.htm
                  </a>
                </p>
                <p>
                  <a href="https://www.immopreise.at/Wien/Wohnung/Miete">
                    https://www.immopreise.at/Wien/Wohnung/Miete
                  </a>
                </p>
                <p>
                  <a href="https://www.ris.bka.gv.at/GeltendeFassung.wxe?Abfrage=Bundesnormen&amp;Gesetzesnummer=20009521">
                    https://www.ris.bka.gv.at/GeltendeFassung.wxe?Abfrage=Bundesnormen&amp;Gesetzesnummer=20009521
                  </a>
                </p>
              </div>
            </div>
          </div>
          <ArticleSidebar blogId={BlogId.AltbauNeubau} />
        </div>
      </div>
    </>
  );
};

export default VorsorgewohnungWienArticle;

import React, { ReactElement } from 'react';
import './Loading.scss';

const Loading = (): ReactElement => (
  <div className="preloader">
    <div className="preloader-spinner">
      <div className="preloader-inner">
        <div className="preloader-inner-circle" />
      </div>
    </div>
  </div>
);

export default Loading;

import React, { ReactElement } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebook,
  faInstagram,
  faTwitter,
  faPinterest,
} from '@fortawesome/free-brands-svg-icons';
import s from './SocialMediaBar.module.scss';

export default function SocialMediaBar(): ReactElement {
  return (
    <div className={s.socialBar}>
      <div className={`${s.item} ${s.facebook}`}>
        <a
          className={s.link}
          rel="noopener nofollow noreferrer"
          target="_blank"
          href="https://www.facebook.com/neubauprojekte.wien"
          title="Facebook"
          aria-label="Facebook"
        >
          <FontAwesomeIcon icon={faFacebook} />
        </a>
      </div>
      <div className={`${s.item} ${s.instagram}`}>
        <a
          className={s.link}
          rel="noopener nofollow noreferrer"
          target="_blank"
          href="https://www.instagram.com/neubauprojekte.wien/"
          title="Instagram"
          aria-label="Instagram"
        >
          <FontAwesomeIcon icon={faInstagram} />
        </a>
      </div>
      <div className={`${s.item} ${s.twitter}`}>
        <a
          className={s.link}
          rel="noopener nofollow noreferrer"
          target="_blank"
          href="https://twitter.com/NeubauprojekteW"
          title="Twitter"
          aria-label="Twitter"
        >
          <FontAwesomeIcon icon={faTwitter} />
        </a>
      </div>
      <div className={`${s.item} ${s.pinterest}`}>
        <a
          className={s.link}
          rel="noopener nofollow noreferrer"
          target="_blank"
          href="https://www.pinterest.at/neubauprojektewien/"
          title="Pinterest"
          aria-label="Pinterest"
        >
          <FontAwesomeIcon icon={faPinterest} />
        </a>
      </div>
    </div>
  );
}

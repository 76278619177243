import React, { ReactElement, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkedAlt, faHome } from '@fortawesome/free-solid-svg-icons';

import logo from './neubauprojekte-wien.svg';
import SocialMediaBar from './SocialMediaBar';
import Hamburger from '../../Hamburger';
import PopupMenu from '../Menubar/PopupMenu';
import s from './Header.module.scss';
import '../../home/home.scss';
import useMediaQuery from '../../../hooks/useMediaQuery';
import TrackableLink from '../../TrackableLink';

export default function Header(): ReactElement {
  const [showMenu, setShowMenu] = useState(false);
  const isBurgermenuSize = useMediaQuery('(max-width: 830px)');
  const hideMenuClass = showMenu ? '' : s.hideMenu;
  return (
    <div className={`${s.headerWrapper} `}>
      <div className={`${s.header} ${hideMenuClass} mainContent`}>
        <TrackableLink
          className={s.logoLink}
          onClick={() => setShowMenu(false)}
          to="/"
        >
          <img alt="Neubauprojekte Wien Logo" className={s.logo} src={logo} />
        </TrackableLink>
        {!isBurgermenuSize && (
          <>
            <div className={s.headerLinks}>
              <a
                className={s.headerLink}
                href="https://www.geförderte-wohnungen.at"
              >
                <FontAwesomeIcon className={s.icon} icon={faHome} />
                <span>Genossenschaftswohnungen</span>
              </a>
              <TrackableLink
                className={s.headerLink}
                onClick={() => {
                  setShowMenu(false);
                }}
                to="/neubau-karte"
              >
                <FontAwesomeIcon className={s.icon} icon={faMapMarkedAlt} />
                <span>Neubau-Karte</span>
              </TrackableLink>
              <div className={`${s.menuItem} ${s.navList} ${s.navLevel1}`}>
                <PopupMenu />
              </div>
            </div>
            <SocialMediaBar />
          </>
        )}
        {isBurgermenuSize && (
          <Hamburger
            className={s.burgerMenu}
            onClick={setShowMenu}
            showMenu={showMenu}
          />
        )}
      </div>
      <div className={s.line} />
    </div>
  );
}

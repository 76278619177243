import React, { ReactElement, useEffect, useState } from 'react';
import useMediaQuery from '../../hooks/useMediaQuery';
import './hamburger.scss';
import HamburgerMenu from './HamburgerMenu';

interface HamburgerProps {
  className?: string;
  showMenu: boolean;
  onClick: (showMenu: boolean) => void;
}

const Hamburger = (props: HamburgerProps): ReactElement => {
  const { onClick, className, showMenu } = props;
  const [isActive, setActive] = useState(false);
  const isBurgermenuSize = useMediaQuery('(max-width: 830px)');

  useEffect(() => {
    if (!isBurgermenuSize) {
      setActive(false);
      onClick(false);
    }
  }, [isBurgermenuSize, onClick]);

  useEffect(() => {
    if (!showMenu) {
      setActive(false);
    }
  }, [showMenu]);

  const isActiveClass = isActive ? 'is-active' : '';
  const toggleBurger = () => {
    setActive(!isActive);
    onClick(!isActive);
  };
  return (
    <>
      <button type="button" onClick={toggleBurger} className={className}>
        <div className={`hamburger hamburger--slider ${isActiveClass}`}>
          <div className={`hamburger-box ${isActiveClass}`}>
            <div className={`hamburger-inner ${isActiveClass}`} />
          </div>
        </div>
      </button>
      {isActive && <HamburgerMenu />}
    </>
  );
};

Hamburger.defaultProps = {
  className: '',
};

export default Hamburger;

import 'react-toastify/dist/ReactToastify.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import { ApolloProvider } from '@apollo/client';
import { ToastContainer } from 'react-toastify';
import React, { ReactElement } from 'react';
import { Helmet } from 'react-helmet';

import client from './client/apollo.client';
import './App.css';
import RouterComponent from './routes';

const App = (): ReactElement => (
  <ApolloProvider client={client}>
    <ToastContainer
      position="top-left"
      autoClose={4000}
      hideProgressBar={false}
      closeOnClick
      draggable
      pauseOnHover
    />
    <Helmet>
      <title>
        Übersicht aktueller Neubauprojekte in Wien - jetzt anfragen!
      </title>

      <meta
        name="description"
        content="Aktuelle Neubauprojekte Wien: Wir zeigen Ihnen alle Projekte mit Neubauwohnungen, neuen Dachgeschosswohnungen und sanierten Altbauwohnungen in Wien."
      />
      <meta
        property="article:publisher"
        content="https://www.facebook.com/neubauprojekte.wien/"
      />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="Übersicht aktueller Neubauprojekte in Wien - Jetzt anfragen!"
      />
      <meta property="og:url" content="https://www.neubauprojekte.wien/" />
      <meta property="og:site_name" content="Neubauprojekte in Wien" />
      <meta property="og:locale" content="de_DE" />

      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="theme-color" content="#000000" />

      <meta name="SKYPE_TOOLBAR" content="SKYPE_TOOLBAR_PARSER_COMPATIBLE" />
      <meta name="theme-color" content="#f5f5f5" />
      <meta
        name="robots"
        content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
      />
      <meta name="msapplication-TileColor" content="#da532c" />
    </Helmet>

    <RouterComponent />
  </ApolloProvider>
);

export default App;

import React, { ReactElement } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import TagManager from 'react-gtm-module';

interface TrackableLinkProps extends LinkProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children?: any;
}

const TrackableLink = (props: TrackableLinkProps): ReactElement => {
  const { children, ...rest } = props;
  return (
    <Link
      {...rest}
      onClick={() => {
        TagManager.dataLayer({
          dataLayer: {
            event: 'button-click',
            pagePath: `https://www.neubauprojekte.wien${rest.to}`,
          },
        });
      }}
    >
      {children}
    </Link>
  );
};

TrackableLink.defaultProps = {
  children: null,
};
export default TrackableLink;

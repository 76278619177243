import { ToastOptions } from 'react-toastify';

export const toastProps: ToastOptions = {
  position: 'top-left',
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  draggable: true,
  progress: undefined,
  pauseOnHover: false,
};

export const googleMaps = {
  apiKey:
    process.env.NODE_ENV === 'development'
      ? 'AIzaSyC4uYRoJTtp586GZjGklWR6l2UDnZl_r4s'
      : 'AIzaSyDJe8KPUl0EzHl9mt00yfHsGTFbgwepQvs',
};

export const googleTag =
  process.env.NODE_ENV === 'production' ? process.env.REACT_APP_GOOGLE_TAG : '';

export const uploadedImageDir = '/public/images';

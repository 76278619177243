import TagManager from 'react-gtm-module';
import { useLocation } from 'react-router-dom';

const usePageTracking = (pageTitle: string): void => {
  const location = useLocation();

  TagManager.dataLayer({
    dataLayer: {
      event: 'pageview',
      pagePath: `https://www.neubauprojekte.wien${location.pathname}`,
      pageTitle,
    },
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
};

export default usePageTracking;

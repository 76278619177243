import React, { ReactElement } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationCircle,
  faHeart,
  faGavel,
  faPen,
  faAngleRight,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { classNames } from 'primereact/utils';

import s from '../Header/Header.module.scss';
import '../../home/home.scss';

interface MenuItemProps {
  link?: string;
  title: string;
  icon?: IconDefinition;
  childMenuItems?: MenuItemProps[];
}
const renderItemContent = (icon, title, childMenuItems) => (
  <>
    {icon && <FontAwesomeIcon className={s.icon} icon={icon} />}
    <span className={s.navItemTitle}>{title}</span>
    {childMenuItems.length > 0 && (
      <div className={s.navItemArrow}>
        <FontAwesomeIcon icon={faAngleRight} />
      </div>
    )}
  </>
);

const MenuItem = (props: MenuItemProps): ReactElement => {
  const { link, title, icon, childMenuItems } = props;
  return (
    <li className={s.menuItem}>
      {link && (
        <a className={s.menuItemAnchor} href={link}>
          {renderItemContent(icon, title, childMenuItems)}
        </a>
      )}
      {!link && (
        <div className={s.menuItemAnchor} style={{ pointerEvents: 'none' }}>
          {renderItemContent(icon, title, childMenuItems)}
        </div>
      )}

      {childMenuItems.length > 0 && (
        <div>
          <ul className={`${s.navList} ${s.navLevel3}`}>
            {childMenuItems.map((child) => (
              <MenuItem
                link={child.link}
                title={child.title}
                icon={child.icon}
                childMenuItems={child.childMenuItems}
              />
            ))}
          </ul>
        </div>
      )}
    </li>
  );
};
MenuItem.defaultProps = {
  link: null,
  icon: null,
  childMenuItems: [],
};

const PopupMenu = (): ReactElement => (
  <div>
    <a href="/" className={s.headerLink}>
      <FontAwesomeIcon className={s.icon} icon={faExclamationCircle} />
      <span style={{ cursor: 'pointer', userSelect: 'none' }}>
        Weitere Infos
      </span>
    </a>

    <ul
      className={classNames({
        [s.navList]: true,
        [s.navLevel2]: true,
      })}
    >
      <MenuItem
        title="Anbieter"
        icon={faGavel}
        childMenuItems={[
          { link: '/bautraeger-wien', title: 'Bauträger' },
          { link: '/genossenschaften', title: 'Genossenschaften' },
          { link: '/makler-wien', title: 'Immobilienmakler' },
        ]}
      />
      <MenuItem
        link="/neubau-blog"
        title="Blog"
        icon={faPen}
        childMenuItems={[
          { link: '/vorsorgewohnung-wien', title: 'Vorsorgewohnung' },
          { link: '/nebenkosten-wohnungskauf', title: 'Nebenkosten' },
          { link: '/immobilienpreise-wien-2020', title: 'Immobilienpreise' },
          { link: '/altbau-oder-neubau', title: 'Altbau oder Neubau?' },
          {
            link: '/gefoerderte-wohnungen-wien',
            title: 'Geförderte Wohnungen',
          },
        ]}
      />
      {/* <MenuItem link="/merkliste" title="Merkliste" icon={faHeart} /> */}
    </ul>
  </div>
);

export default PopupMenu;

import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import App from './App';
import './index.css';
import 'typeface-roboto';
import { googleTag } from './utils/configs';

if (process.env.NODE_ENV === 'production') {
  const tagManagerArgs = {
    gtmId: googleTag,
  };
  TagManager.initialize(tagManagerArgs);
}

ReactDOM.render(<App />, document.getElementById('root'));

import React, { ReactElement } from 'react';
import s from './Footer.module.scss';

const Claim = (): ReactElement => (
  <div className={s.claim}>
    <span>
      Alle Rechte vorbehalten. © {new Date().getFullYear()} neubauprojekte.wien
    </span>
  </div>
);

export default Claim;

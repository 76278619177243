import React, { ReactElement } from 'react';
import { Helmet } from 'react-helmet';

import Block from '../../Layout/Block';
import { BlogCategories, BlogId } from '../Blogs';

import './Article.scss';
import ArticleBreadcrumbs from './ArticleBreadcrumbs';
import ArticleContents from './ArticleContents';
import ArticleSidebar from './ArticleSidebar';
import usePageTracking from '../../../hooks/usePageTracking';

const NebenkostenWohnungskaufArticle = (): ReactElement => {
  const firstParagraph =
    'Beim Kauf einer Wohnung fallen Nebenkosten an, die vor dem Kauf exakt kalkulierbar sind. Die Nebenkosten werden zum Großteil prozentuell berechnet und unterscheiden sich von Wohnung zu Wohnung.';

  usePageTracking('Nebenkosten Wohnungskauf - Alle anfallenden Kosten.');

  return (
    <>
      <Helmet>
        <title>Nebenkosten Wohnungskauf - Alle anfallenden Kosten.</title>
        <meta name="description" content={firstParagraph} />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Nebenkosten Wohnungskauf - Alle anfallenden Kosten."
        />
        <meta property="og:description" content={firstParagraph} />
        <meta
          property="og:url"
          content="https://www.neubauprojekte.wien/nebenkosten-wohnungskauf/"
        />
        <meta
          property="article:published_time"
          content="2020-06-20T10:00:16+00:00"
        />
        <meta
          property="article:modified_time"
          content="2020-08-16T14:29:10+00:00"
        />
      </Helmet>
      <div className="mainContent page article">
        <div className="p-grid">
          <div className="lg:col-8">
            <div className="article-content">
              <ArticleBreadcrumbs
                category={BlogCategories.RatgeberEigentumswohnung}
              />
              <h1 className="firstHeader">Nebenkosten Wohnungskauf</h1>
              <div className="separator" />
              <Block>
                <p>{firstParagraph}</p>
                <p style={{ marginTop: 8 }}>Die Nebenkosten umfassen:</p>
                <div className="list-wrapper">
                  <ul>
                    <li>
                      <strong>Kaufnebenkosten</strong> – Kosten für die
                      Abwicklung des Kaufs
                    </li>
                    <li>
                      <strong>Finanzierungsnebenkosten</strong>&nbsp; – Kosten
                      für die Kreditfinanzierung
                    </li>
                  </ul>
                </div>
              </Block>

              <ArticleContents
                content={[
                  {
                    section: 'Kaufnebenkosten',
                    children: [
                      'Grunderwerbsteuer',
                      'Eintragungsgebühr',
                      'Vertragserrichtung',
                      'Treuhandschaft',
                      'Maklerhonorar',
                      'Beglaubigungskosten',
                    ],
                  },
                  {
                    section: 'Finanzierungsnebenkosten',
                    children: [
                      'Eintragungsgebühr Pfandrecht',
                      'Beglaubigungskosten',
                      'Bearbeitungsgebühr',
                    ],
                  },
                ]}
              />
              <div className="separator" />
              <h2 id="1">Kaufnebenkosten</h2>
              <Block>
                In der Regel sind die Kaufnebenkosten transparent im Kaufvertrag
                der Eigentumswohnung aufgelistet. Bei den Kaufnebenkosten gibt
                es Positionen, bei denen die Höhe der Kosten immer gleich
                berechnet werden und Positionen, die sich von von Neubauprojekt
                zu Neubauprojekt unterscheiden. Für die Kaufnebenkosten müssen
                Sie zwischen 6,6 und 11,6% des Kaufpreises einberechnen.
              </Block>

              <p style={{ marginBottom: 20, textDecoration: 'underline' }}>
                Folgende Kaufnebenkosten werden immer gleich berechnet:
              </p>
              <h3 id="2">3,5% Grunderwerbsteuer</h3>
              <Block>
                Beim Erwerb einer Wohnung fallen 3,5% Grunderwerbsteuer vom
                vereinbarten Kaufpreis an. Es handelt sich dabei um eine Abgabe
                an den Bund und die Gemeinden. Üblicherweise wird im Kaufvertrag
                vereinbart, dass der Käufer die Grunderwerbsteuer und die
                Eintragungsgebühr übernimmt.
              </Block>
              <h3 id="3">1,1% Eintragungsgebühr</h3>
              <Block>
                Die Eintragungsgebühr beträgt 1,1% des Kaufpreises der Wohnung
                und fällt ebenso beim Erwerb einer Wohnung an. Unter der
                Eintragungsgebühr versteht man jene Gebühr, die aufgrund der
                Eintragung des Käufers im Grundbuch (Verbücherung) anfällt.
                Gleichzeitig wird vom Gericht eine Eingabegebühr in der Höhe von
                44 Euro für das Grundbuchsgesuch vorgeschrieben.
              </Block>

              <Block>
                <p>
                  Die Grunderwerbsteuer und die Eintragungsgebühr werden
                  gemeinsam mit dem Kaufpreis an den Treuhänder überwiesen.
                  Dieser führt die Steuer bzw. die Gebühr direkt ans Finanzamt
                  ab.
                </p>
              </Block>
              <p style={{ marginBottom: 20, textDecoration: 'underline' }}>
                Folgende Kaufnebenkosten unterscheiden sich von Neubauprojekt zu
                Neubauprojekt:
              </p>
              <h3 id="4">1-3% Vertragserrichtung</h3>
              <Block>
                Die Kosten für die Vertragserrichtung betragen ca. 1-3% des
                Kaufpreises. Darunter sind jene Kosten zu verstehen, die für die
                Errichtung des Kaufvertrags durch den Rechtsanwalt bzw. die
                Rechtsabteilung des Bauherren anfallen. Die Kosten für die
                Vertragserrichtung sind beim beim Einzelabverkauf des
                Neubauprojektes in der Regel nicht verhandelbar.
              </Block>
              <h3 id="5">1% Treuhandschaft</h3>
              <Block>
                Achten Sie darauf, dass im Kaufvertrag zu Ihrer Sicherheit ein
                Treuhänder vereinbart wird. Für die Treuhandschaft sind ca. 1%
                des Kaufpreises einzukalkulieren. Ein Treuhänder dient als
                Mittelsmann zwischen Verkäufer und Käufer. Er nimmt den
                Kaufpreis vom Käufer entgegen und zahlt Ihn gem. dem
                Bauträgervertragsgesetz an den Verkäufer aus. So kann der Käufer
                sicher gehen, dass der Kaufpreis erst fließt, wenn das
                Eigentumsrecht zugunsten des Käufers im Grundbuch einverleibt
                wurde. Die Treuhandschaft kann von einem Notar oder einem
                Treuhänder übernommen werden. Es ist durchaus auch üblich, dass
                der Vertragserrichter mit der Treuhandschaft beauftragt wird.
              </Block>
              <h3 id="6">3% Maklerhonorar (+20% USt)</h3>
              <Block>
                Bei Vermittlung einer Wohnung über einen Makler kann eine
                Provision fällig werden. Bauträger verkaufen in der Regel
                provisionsfrei. Ebenso ist zu beachten, dass Makler Wohnungen
                eines Neubauprojekts auch teilweise provisionsfrei für den
                Käufer anbieten. Sollte aufgrund des Kaufs einer Wohnung eine
                Provision anfallen, beträgt diese überlicherweise 3% des
                Kaufpreises zuzüglich 20% Umsatzsteuer (auf die 3% des
                Kaufpreises). Eine Provision wird nur fällig, wenn auf diese
                ausdrücklich hingewiesen wurde bzw. im Kaufvertrag vereinbart
                wurde
              </Block>
              <h3 id="7">Beglaubigungskosten</h3>
              <Block>
                Die Beglaubigungskosten sind abhängig von der Höhe des
                Kaufpreises und werden in Notarstarifgesetz vereinbart. Es
                fallen sowohl Kosten für die Beglaubigung des Kaufvertrags sowie
                für die Beglaubigung der Treuhandvereinbarung an.
              </Block>
              <h2 id="8">Finanzierungsnebenkosten</h2>
              <h3 id="9">1,2% Eintragungsgebühr Pfandrecht</h3>
              <Block>
                Die Pfandeintragungsgebühr beträgt 1,2% des Pfandbetrags und
                wird vom zuständigen Gericht vorgeschrieben.. Unter dem
                Pfandbetrag ist jener Betrag zu verstehen, der als Kredit von
                der Bank aufgenommen wird zuzüglich Nebenforderungen (Zinsen,
                Verzugs- und Zinseszinsen, Rechtsanwaltskosten, Exekutionskosten
                etc.), die sichergestellt werden müssen. Das Pfandrecht wird als
                Sicherheit für die Bank im C-Blatt des Grundbuchs eintgeragen
                bzw. in Form einer Pfandrechtsurkunde im Grundbuch hinterlegt.
              </Block>
              <h3 id="10">Beglaubigungskosten</h3>
              <Block>
                Die Pfandrechtsurkunde muss notariell beglaubigt werden. Die
                Höhe der Beglaubigungskosten ist wieder abhängig von der Höhe
                des Kaufpreises.
              </Block>
              <h3 id="11">Bearbeitungsgebühr</h3>
              <Block>
                Unter der Bearbeitungsgebühr ist jene Gebühr zu verstehen, die
                die Bank im Zuge der Abwicklung des Kredites dem Kreditnehmer
                vorschreibt. Hierunter fällt nicht nur die organisatorische
                Abwicklung, sondern auch eine interne Immobilienbewertung des
                Kaufgegenstandes.
              </Block>
            </div>
          </div>
          <ArticleSidebar blogId={BlogId.AltbauNeubau} />
        </div>
      </div>
    </>
  );
};

export default NebenkostenWohnungskaufArticle;

import React, { FunctionComponent, ReactElement } from 'react';
import Header from './Header';
import Footer from './Footer';
import './Main.scss';

interface LayoutProps {
  withAdminTopBar?: boolean;
}

const Layout: FunctionComponent<LayoutProps> = (props): ReactElement => {
  let mainClass = 'main';
  const { withAdminTopBar, children } = props;
  if (withAdminTopBar) {
    mainClass = `${mainClass} withAdminBar`;
  }
  return (
    <div className={mainClass}>
      <Header />
      <div className="content">{children}</div>
      <Footer />
    </div>
  );
};

Layout.defaultProps = {
  withAdminTopBar: false,
};

export default Layout;

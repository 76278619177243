import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  ReactElement,
} from 'react';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import { gql, useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';

import { hashData } from '../../shared';
import { toastProps } from '../../utils/configs';
import history from '../../history';
import { Me } from '../../shared/schema/UserSchema';

interface ContextType {
  loggedIn: boolean;
  me: Me;
  login: (password: string, email: string, captcha: string) => void;
  logout: () => void;
}

const authContext = createContext({} as ContextType);

export const storageTokenName = 'token';

const LOGIN_MUTATION = gql`
  mutation Login($input: LoginInput!) {
    login(input: $input)
  }
`;

export const ME_QUERY = gql`
  query me {
    me {
      id
      username
    }
  }
`;

export const useProvideAuth = () => {
  const [state, setState] = useState({
    loading: true,
    loggedIn: false,
    me: null,
  });

  const { loading: loadingMe, data: me } = useQuery(ME_QUERY);
  const [loginRequest, { loading }] = useMutation(LOGIN_MUTATION, {
    refetchQueries: ({ data }) => {
      Cookies.set(storageTokenName, data.login, { expires: 7 });
      return [
        {
          query: ME_QUERY,
        },
      ];
    },
    awaitRefetchQueries: true,
  });

  useEffect(() => {
    if (!Cookies.get(storageTokenName)) {
      setState({ loggedIn: false, me: null, loading: false });
      return;
    }

    if (!loadingMe) {
      if (me) {
        setState({ loggedIn: true, me, loading: false });
      } else {
        setState({ loggedIn: false, me: null, loading: false });
      }
    }
  }, [me, loadingMe]);

  async function login(password, email, captcha) {
    try {
      const res = await loginRequest({
        variables: {
          input: { email, password: hashData(password), captcha },
        },
      });
      if (res?.data?.login?.token) {
        await toast.success('Login erfolgreich', toastProps);
      }
    } catch (e) {
      toast.error('Login fehlgeschlagen', toastProps);
    }
  }

  function logout() {
    Cookies.remove(storageTokenName);
    setState({ loggedIn: false, me: null, loading: false });
    history.push('/');
    history.go(0);
  }

  return {
    login,
    logout,
    loggedIn: state.loggedIn,
    loading: state.loading || loading,
    me: state.me,
  };
};

function AuthProvider({ children }): ReactElement {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}> {children}</authContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useAuth = () => useContext(authContext);

export default AuthProvider;

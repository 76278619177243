import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactElement } from 'react';
import { blogCategories, BlogCategories } from '../Blogs';

import './ArticleBreadcrumbs.scss';

interface ArticleBreadcrumbsProps {
  category: BlogCategories;
}

const ArticleBreadcrumbs = ({
  category,
}: ArticleBreadcrumbsProps): ReactElement => (
  <ol
    className="articleBreadcrumbs"
    itemType="http://schema.org/BreadcrumbList"
  >
    <li
      className="breadcrumbsItem"
      itemProp="itemListElement"
      itemType="http://schema.org/ListItem"
    >
      <a itemProp="item" href="/">
        <span itemProp="name">Startseite</span>
      </a>
      <meta itemProp="position" content="1" />
    </li>
    <li className="breadcrumbs-separator">
      <i className="far fa-angle-right" />
      <span className="arrow">
        <FontAwesomeIcon icon={faAngleRight} />
      </span>
    </li>
    <li
      className="breadcrumbsItem"
      itemProp="itemListElement"
      itemType="http://schema.org/ListItem"
    >
      <a
        itemProp="item"
        href={`/category/${
          blogCategories.find((c) => c.id === category).link
        }/`}
      >
        <span itemProp="name">
          {blogCategories.find((c) => c.id === category).title}
        </span>
      </a>
      <meta itemProp="position" content="2" />
    </li>
  </ol>
);

export default ArticleBreadcrumbs;

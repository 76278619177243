import React, { lazy, ReactElement, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';

import Loading from '../components/Loading';
import Layout from '../components/Layout';
import BlogOverview from '../components/Blog/BlogOverview';
import AltbauOderNeubauArticle from '../components/Blog/Article/AltbauOderNeubauArticle';
import VorsorgewohnungWienArticle from '../components/Blog/Article/VorsorgewohnungWienArticle';
import NebenkostenWohnungskaufArticle from '../components/Blog/Article/NebenkostenWohnungskaufArticle';
import GefoerderteWohnungenArticle from '../components/Blog/Article/GefoerderteWohnungenArticle.tsx';

const Home = lazy(() => import('../components/home'));
const ViennaProjectsMap = lazy(
  () => import('../components/Map/ViennaProjectsMap')
);
const Portfolio = lazy(() => import('../components/Portfolio'));

const NotFound = lazy(() => import('../components/notFound'));
const Bautraeger = lazy(
  () => import('../components/Information/Bautraeger/Bautraeger')
);
const Genossenschaften = lazy(
  () => import('../components/Information/Genossenschaften/Genossenschaften')
);
const Makler = lazy(() => import('../components/Information/Makler/Makler'));
const Partner = lazy(() => import('../components/Information/Partner/Partner'));
const ImmobilienPreise = lazy(
  () => import('../components/Misc/ImmobilienPreise/ImmobilienPreise')
);
const Downloads = lazy(() => import('../components/Misc/Downloads/Downloads'));
const Contact = lazy(() => import('../components/Misc/Contact/Contact'));
const Impressum = lazy(() => import('../components/Misc/Impressum/Impressum'));
const Inserieren = lazy(
  () => import('../components/Misc/Inserieren/Inserieren')
);
const BautraegerInfo = lazy(
  () => import('../components/Information/Bautraeger/BautraegerInfo')
);
const Datenschutz = lazy(
  () => import('../components/Misc/Datenschutz/Datenschutz')
);

const routes: any = [
  {
    path: '/',
    component: Home,
    exact: true,
  },
  {
    path: '/portfolio/:url',
    component: Portfolio,
  },
  {
    path: '/bautraeger-wien',
    component: Bautraeger,
    exact: true,
  },
  {
    path: '/bautraeger/:url',
    component: BautraegerInfo,
  },
  {
    path: '/genossenschaften',
    component: Genossenschaften,
    exact: true,
  },
  {
    path: '/makler-wien',
    component: Makler,
    exact: true,
  },
  {
    path: '/partner',
    component: Partner,
    exact: true,
  },
  {
    path: '/neubau-karte',
    component: ViennaProjectsMap,
    exact: true,
  },
  {
    path: '/immobilienpreise-wien-2020',
    component: ImmobilienPreise,
    exact: true,
  },
  {
    path: '/downloads',
    component: Downloads,
    exact: true,
  },
  {
    path: '/datenschutz',
    component: Datenschutz,
    exact: true,
  },
  {
    path: '/kontakt',
    component: Contact,
    exact: true,
  },
  {
    path: '/impressum',
    component: Impressum,
    exact: true,
  },
  {
    path: '/neubauprojekte-inserieren',
    component: Inserieren,
    exact: true,
  },
  {
    path: '/neubau-blog',
    component: BlogOverview,
    exact: true,
  },
  {
    path: '/altbau-oder-neubau',
    component: AltbauOderNeubauArticle,
    exact: true,
  },
  {
    path: '/vorsorgewohnung-wien',
    component: VorsorgewohnungWienArticle,
    exact: true,
  },
  {
    path: '/nebenkosten-wohnungskauf',
    component: NebenkostenWohnungskaufArticle,
    exact: true,
  },
  {
    path: '/gefoerderte-wohnungen-wien',
    component: GefoerderteWohnungenArticle,
    exact: true,
  },
  {
    path: '/category/ratgeber-eigentumswohnung',
    component: BlogOverview,
    exact: true,
  },
  {
    path: '/category/gefoerderte-wohnung',
    component: BlogOverview,
    exact: true,
  },
  {
    path: '/category/immobilienmarkt',
    component: BlogOverview,
    exact: true,
  },
];

const PublicRoutes = (): ReactElement => (
  <Layout>
    <Switch>
      {routes.map((route) => {
        const ContentComponent = route.component;
        return (
          <Route
            key={route.path}
            path={route.path}
            render={(props) => (
              <Suspense fallback={<Loading />}>
                <ContentComponent {...props} />
              </Suspense>
            )}
            exact={route.exact}
          />
        );
      })}

      <Route
        render={() => (
          <Suspense fallback={<Loading />}>
            <NotFound />
          </Suspense>
        )}
      />
    </Switch>
  </Layout>
);

export default PublicRoutes;

import React, { ReactElement } from 'react';
import s from './Footer.module.scss';
import TrackableLink from '../../TrackableLink';

interface ColumnLink {
  label: string;
  link: string;
}

interface Column {
  header: string;
  links: ColumnLink[];
}

const columns: Column[] = [
  {
    header: 'Informationen',
    links: [
      {
        label: 'Neubau-Blog',
        link: '/neubau-blog',
      },
      {
        label: 'Neubau-Karte',
        link: '/neubau-karte/',
      },
      {
        label: 'Partner',
        link: '/partner/',
      },
      {
        label: 'Projekte inserieren',
        link: '/neubauprojekte-inserieren',
      },
    ],
  },
  {
    header: 'Neubauprojekte',
    links: [
      {
        label: 'Bauträger in Wien',
        link: '/bautraeger-wien',
      },
      {
        label: 'Genossenschaften in Wien',
        link: '/genossenschaften',
      },
      {
        label: 'Immobilienmakler Wien',
        link: '/makler-wien/',
      },
    ],
  },
  {
    header: 'Informationen',
    links: [
      {
        label: 'Kontakt',
        link: '/kontakt',
      },
      {
        label: 'Datenschutz',
        link: '/datenschutz',
      },
      {
        label: 'Impressum',
        link: '/impressum',
      },
    ],
  },
  {
    header: 'Weitere Seiten',
    links: [
      {
        label: 'grundbuch-auszug.at',
        link: 'https://www.grundbuch-auszug.at',
      },
      {
        label: 'geförderte-wohnungen.at',
        link: 'https://www.xn--gefrderte-wohnungen-s6b.at/',
      },
    ],
  },
];

const renderColumn = (c: Column) => (
  <div key={c.header} className={`${s.colWrapper} ${s.vertical}`}>
    <div className={s.footerHeader}>
      <h4>{c.header}</h4>
    </div>
    <div className={s.smallSeparator} />
    {c.links.map((l) => (
      <div key={l.label} className={s.row}>
        {l.link.startsWith('/') ? (
          <TrackableLink to={l.link}>- {l.label}</TrackableLink>
        ) : (
          <a href={l.link} target="_blank" rel="noreferrer">
            - {l.label}
          </a>
        )}
      </div>
    ))}
  </div>
);
function Misc(): ReactElement {
  return (
    <>
      <div
        className={`${s.footerSection} ${s.footerElement} ${s.miscContainer}`}
      >
        {columns.map((c) => renderColumn(c))}
      </div>
    </>
  );
}
export default Misc;

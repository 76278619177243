import Cookies from 'js-cookie';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client';

const authLink = setContext((_, { headers }) => {
  const token = Cookies.get('token');
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});
const API_URL =
  process.env.NODE_ENV === 'production'
    ? `/api`
    : `${window.location.protocol}//${window.location.hostname}:3000/api`;

// eslint-disable-next-line new-cap
const uploadLink = new createUploadLink({
  uri: API_URL,
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      // eslint-disable-next-line no-console
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  // eslint-disable-next-line no-console
  if (networkError) console.log(`[Network error]: ${networkError}`);
});
const link = ApolloLink.from([errorLink, authLink, uploadLink]);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache({
    addTypename: false,
    typePolicies: {
      Query: {
        fields: {
          getPublicProjects: {
            read(existing, { args: { offset = 0, limit } }) {
              // A read function should always return undefined if existing is
              // undefined. Returning undefined signals that the field is
              // missing from the cache, which instructs Apollo Client to
              // fetch its value from your GraphQL server.
              return (
                existing && {
                  getPublicProjects: {
                    projects: existing.projects.slice(offset, offset + limit),
                    count: existing.count,
                  },
                }
              );
            },
            keyArgs: false,
            merge(existing, incoming, { args: { offset = 0 } }) {
              // Slicing is necessary because the existing data is
              // immutable, and frozen in development.
              const merged = existing ? existing.projects.slice(0) : [];
              // eslint-disable-next-line no-plusplus
              for (let i = 0; i < incoming.projects.length; ++i) {
                merged[offset + i] = incoming.projects[i];
              }
              return {
                projects: merged,
                count: incoming.count,
              };
            },
          },
        },
      },
    },
  }),
});

export default client;

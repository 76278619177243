import React, { ReactElement } from 'react';
import bitterlKoenig from './bitterl-koenig-rechtsanwalt-immobilienrecht.svg';
import krist from './krist-rechtsanwälte.svg';
import meller from './meller-rechtsanwalt-immobilienrecht.svg';
import standfest from './standfest-rechtsanwalt-immobilien.svg';
import s from './Footer.module.scss';

interface Partner {
  text: string;
  image: string;
  link: string;
}

const partner: Partner[] = [
  {
    text: 'Ihr Rechtsanwalt für Immobilien- und Erbrecht & Vertragsschluss.',
    image: meller,
    link: 'https://www.ra-meller.at/',
  },
  {
    text: 'Ihre Rechtsanwaltskanzlei für Immobilien- und Mietrecht, Baurecht und Vertragsrecht.',
    image: bitterlKoenig,
    link: 'https://www.bklegal.at/',
  },
  {
    text: 'Ihr Rechtsanwalt für Immobilienprojekte mit persönlicher Beratung und individuellen Lösungen.',
    image: standfest,
    link: 'https://rechtsanwalt-standfest.at/Auswahl',
  },
  {
    text: 'Rechtsberatung für nationale und internationale Unternehmen, Körperschaften und Privatpersonen.',
    image: krist,
    link: 'https://ra-krist.at/',
  },
];

const renderPartner = (p: Partner): ReactElement => (
  <div className={s.row}>
    <a href={p.link}>
      <img className={s.image} alt={p.text} src={p.image} />
    </a>
    <p className={s.multiColumnDesc}>{p.text}</p>
  </div>
);

const BezirkFilter = (): ReactElement => (
  <div className={s.footerElement}>
    <div className={s.footerHeader}>
      <h4>Unsere Partner & Empfehlungen</h4>
    </div>
    <div className={s.smallSeparator} />
    <div className={s.footerSection}>
      <div className={s.colWrapper}>
        <div className={s.col}>{renderPartner(partner[0])}</div>
        <div className={s.col}>{renderPartner(partner[1])}</div>
      </div>
      <div className={s.colWrapper}>
        <div className={s.col}>{renderPartner(partner[2])}</div>
        <div className={s.col}>{renderPartner(partner[3])}</div>
      </div>
    </div>
  </div>
);

export default BezirkFilter;

import React, { ReactElement } from 'react';

import './ArticleContents.scss';

interface SubContent {
  section: string;
  children: string[];
}

interface ArticleContentsProps {
  content: Array<string | SubContent>;
}

const ArticleContents = ({ content }: ArticleContentsProps): ReactElement => {
  let index = 0;
  return (
    <div className="articleContents">
      <div className="wpb_wrapper">
        <div className="IV">
          <div className="IVT">Inhaltsverzeichnis</div>
          {content.map((c) => {
            if (typeof c === 'string') {
              index += 1;
              return (
                <div className="IVH1">
                  <a href={`#${index}`}>{c}</a>
                </div>
              );
            }

            index += 1;
            return (
              <>
                <div className="IVH1">
                  <a href={`#${index}`}>Altbau vs. Neubau</a>
                </div>
                <div className="IVH2">
                  {c.children.map((child, i) => {
                    index += 1;
                    return (
                      <>
                        {i !== 0 ? <br /> : null}
                        <a href={`#${index}`}>-{child}</a>
                      </>
                    );
                  })}
                </div>
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ArticleContents;

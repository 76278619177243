import React, { ReactElement } from 'react';
import BezirkFilter from './BezirkFilter';
import PartnerFooter from './Partner';
import Misc from './Misc';
import Claim from './Claim';
import s from './Footer.module.scss';
import '../../home/home.scss';

export default function Footer(): ReactElement {
  return (
    <div className={`${s.footer} mainContent footer`}>
      <BezirkFilter />
      <PartnerFooter />
      <Misc />
      <Claim />
    </div>
  );
}

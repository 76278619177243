import React, { ReactElement } from 'react';
import { PanelMenu } from 'primereact/panelmenu';
import { classNames } from 'primereact/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDown,
  faBook,
  faExclamationCircle,
  faGavel,
  faHeart,
  faHome,
  faMapMarkedAlt,
  faPen,
} from '@fortawesome/free-solid-svg-icons';

import s from '../Layout/Header/Header.module.scss';
import './hamburger.scss';

const HamburgerItemTemplate = (item, options) => {
  const { onClick } = options;
  const { icon, link, label, items } = item;

  return (
    <a
      className={classNames({
        anchor: true,
        [s.menuItemAnchor]: true,
        collapsableItem: items?.length > 0,
      })}
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        cursor: 'pointer',
      }}
      href={link}
      onClick={(e) => {
        if (!link) {
          onClick(e);
        }
      }}
    >
      <div>
        {icon && <FontAwesomeIcon icon={icon} style={{ marginRight: 5 }} />}
        <span>{label}</span>
      </div>
      {items?.length > 0 && (
        <span>
          <FontAwesomeIcon
            icon={faAngleDown}
            style={{ width: '2.6rem', fontSize: '1.5rem' }}
          />
        </span>
      )}
    </a>
  );
};

const HamburgerMenu = (): ReactElement => {
  const items = [
    {
      label: 'Genossenschaftswohnungen',
      icon: faHome,
      link: '/',
      template: HamburgerItemTemplate,
    },
    {
      label: 'Grundbuch',
      icon: faBook,
      link: 'https://www.grundbuch-auszug.at',
      template: HamburgerItemTemplate,
    },
    {
      label: 'Neubau-Karte',
      icon: faMapMarkedAlt,
      link: '/neubau-karte',
      template: HamburgerItemTemplate,
    },
    {
      label: 'Weitere Infos',
      icon: faExclamationCircle,
      template: HamburgerItemTemplate,
      items: [
        {
          label: 'Anbieter',
          icon: faGavel,
          template: HamburgerItemTemplate,
          items: [
            {
              label: 'Bauträger',
              link: '/bautraeger-wien',
              template: HamburgerItemTemplate,
            },
            {
              label: 'Genossenschaften',
              link: '/genossenschaften',
              template: HamburgerItemTemplate,
            },
            {
              label: 'Immobilienmakler',
              link: '/makler-wien',
              template: HamburgerItemTemplate,
            },
          ],
        },
        {
          label: 'Blog',
          icon: faPen,
          items: [
            {
              label: 'Vorsorgewohnung',
              link: '/vorsorgewohnung-wien',
              template: HamburgerItemTemplate,
            },
            {
              label: 'Nebenkosten',
              link: '/nebenkosten-wohnungskauf',
              template: HamburgerItemTemplate,
            },
            {
              label: 'Immobilienpreise',
              link: '/immobilienpreise-wien-2020',
              template: HamburgerItemTemplate,
            },
            {
              label: 'Altbau oder Neubau?',
              link: '/altbau-oder-neubau',
              template: HamburgerItemTemplate,
            },
            {
              label: 'Geförderte Wohnungen',
              link: '/gefoerderte-wohnungen-wien',
              template: HamburgerItemTemplate,
            },
          ],
          template: HamburgerItemTemplate,
        },
        // {
        //   label: 'Merkliste',
        //   link: '/merkliste',
        //   icon: faHeart,
        //   template: HamburgerItemTemplate,
        // },
      ],
    },
  ];
  return (
    <div className="hamburger-menu">
      <PanelMenu model={items} />
    </div>
  );
};

export default HamburgerMenu;

import React, { ReactElement } from 'react';
import s from './CustomButton.module.scss';
import TrackableLink from '../TrackableLink';

interface LinkButtonProps {
  text: string;
  link: string;
}

const LinkButton = (props: LinkButtonProps): ReactElement => {
  const { text, link } = props;
  const isExternal = link.startsWith('http');

  const linkBody = (
    <div className={s.button}>
      <span>{text}</span>
    </div>
  );
  return (
    <div className={s.wrapper}>
      {isExternal && (
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          className={s.wrapper}
        >
          {linkBody}
        </a>
      )}
      {!isExternal && <TrackableLink to={link}>{linkBody}</TrackableLink>}
    </div>
  );
};

export default LinkButton;

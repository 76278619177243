import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactElement } from 'react';
import {
  faFacebook,
  faInstagram,
  faTwitter,
  faPinterest,
} from '@fortawesome/free-brands-svg-icons';

import './Article.scss';

const SocialMediaCards = (): ReactElement => (
  <div className="socialMedia">
    <h3 className="widgetTitle">SocialMedia</h3>
    <div className="socialsList" style={{ fontSize: '20px' }}>
      <div className="socialsItem facebook">
        <a
          className="socialsItem-link"
          target="_blank"
          rel="noopener nofollow noreferrer"
          href="https://www.facebook.com/neubauprojekte.wien/"
          aria-label="Facebook"
        >
          <div className="socialsItemHover">
            <FontAwesomeIcon icon={faFacebook} />
          </div>
        </a>
      </div>
      <div className="socialsItem twitter">
        <a
          className="socialsItem-link"
          target="_blank"
          rel="noopener nofollow noreferrer"
          href="https://twitter.com/NeubauprojekteW"
          aria-label="Twitter"
        >
          <div className="socialsItemHover">
            <FontAwesomeIcon icon={faTwitter} />
          </div>
        </a>
      </div>
      <div className="socialsItem instagram">
        <a
          className="socialsItem-link"
          target="_blank"
          rel="noopener nofollow noreferrer"
          href="https://www.instagram.com/neubauprojekte.wien/"
          aria-label="Instagram"
        >
          <div className="socialsItemHover">
            <FontAwesomeIcon icon={faInstagram} />
          </div>
        </a>
      </div>
      <div className="socialsItem pinterest">
        <a
          className="socialsItem-link"
          target="_blank"
          rel="noopener nofollow noreferrer"
          href="https://www.pinterest.at/neubauprojektewien/"
          aria-label="Pinterest"
        >
          <div className="socialsItemHover">
            <FontAwesomeIcon icon={faPinterest} />
          </div>
        </a>
      </div>
    </div>
  </div>
);

export default SocialMediaCards;

import { Router, Switch, Route } from 'react-router-dom';
import React, { Suspense, lazy, ReactElement } from 'react';
import history from '../history';
import PublicRoutes from './publicRoutes';
import Loading from '../components/Loading';
import AuthProvider from '../components/Auth/AuthProvider';

const PrivateRoutes = lazy(() => import('./privateRoutes'));
const Login = lazy(() => import('../components/Login'));

function RouterComponent(): ReactElement {
  history.listen(() => {
    // eslint-disable-next-line no-undef
    window.scrollTo(0, 0);
  });
  return (
    <Router history={history}>
      <AuthProvider>
        <Switch>
          <Route
            path="/admin"
            render={() => (
              <>
                <Suspense fallback={<Loading />}>
                  <Switch>
                    <Route path="/admin/login" component={Login} />
                    <Route component={PrivateRoutes} />
                  </Switch>
                </Suspense>
              </>
            )}
          />
          <Route component={PublicRoutes} />
        </Switch>
      </AuthProvider>
    </Router>
  );
}

export default RouterComponent;

import React, { ReactElement } from 'react';

import s from './Footer.module.scss';
import TrackableLink from '../../TrackableLink';

interface Bezirk {
  number: number;
  name: string;
  link: string;
}

export const bezirke: Bezirk[] = [
  { number: 1010, name: '1010 – Innere Stadt', link: '?zip=1010' },
  { number: 1020, name: '1020 – Leopoldstadt', link: '?zip=1020' },
  { number: 1030, name: '1030 – Landstraße', link: '?zip=1030' },
  { number: 1040, name: '1040 – Wieden', link: '?zip=1040' },
  { number: 1050, name: '1050 – Magareten', link: '?zip=1050' },
  { number: 1060, name: '1060 – Mariahilf', link: '?zip=1060' },
  { number: 1070, name: '1070 – Neubau', link: '?zip=1070' },
  { number: 1080, name: '1080 – Josefstadt', link: '?zip=1080' },
  { number: 1090, name: '1090 – Alsergrund', link: '?zip=1090' },
  { number: 1100, name: '1100 – Favoriten', link: '?zip=1100' },
  { number: 1110, name: '1110 – Simmering', link: '?zip=1110' },
  { number: 1120, name: '1120 – Meidling', link: '?zip=1120' },
  { number: 1130, name: '1130 – Hietzing', link: '?zip=1130' },
  { number: 1140, name: '1140 – Penzing', link: '?zip=1140' },
  { number: 1150, name: '1150 – Rudolfsheim', link: '?zip=1150' },
  { number: 1160, name: '1160 – Ottakring', link: '?zip=1160' },
  { number: 1170, name: '1170 – Hernals', link: '?zip=1170' },
  { number: 1180, name: '1180 – Währing', link: '?zip=1180' },
  { number: 1190, name: '1190 – Döbling', link: '?zip=1190' },
  { number: 1200, name: '1200 – Brigittenau', link: '?zip=1200' },
  { number: 1210, name: '1210 – Floridsdorf', link: '?zip=1210' },
  { number: 1220, name: '1220 – Donaustadt', link: '?zip=1220' },
  { number: 1230, name: '1230 – Liesing', link: '?zip=1230' },
];

const renderBezirk = (b: Bezirk) => (
  <div key={b.name} className={s.row}>
    <TrackableLink to={`/${b.link}`}>{b.name}</TrackableLink>
  </div>
);

const renderColumn = (from: number, to: number) => {
  const columns = [];
  for (let i = from; i <= to && i < bezirke.length; i += 1) {
    columns.push(renderBezirk(bezirke[i]));
  }
  return columns;
};

const BezirkFilter = (): ReactElement => (
  <div className={s.footerElement}>
    <div className={s.footerHeader}>
      <h4>Eigentums- und Mietwohnungen nach Bezirk</h4>
    </div>
    <div className={s.smallSeparator} />
    <div className={s.footerSection}>
      <div className={s.colWrapper}>
        <div className={s.col}>{renderColumn(0, 5)}</div>
        <div className={s.col}>{renderColumn(6, 11)}</div>
      </div>
      <div className={s.colWrapper}>
        <div className={s.col}>{renderColumn(12, 17)}</div>
        <div className={s.col}>{renderColumn(18, 23)}</div>
      </div>
    </div>
  </div>
);

export default BezirkFilter;

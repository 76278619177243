import React, { CSSProperties, ReactElement, ReactNode } from 'react';

interface BlockProps {
  style?: CSSProperties;
  children?: ReactNode;
}

const Block = (props: BlockProps): ReactElement => {
  const { children, style } = props;
  return (
    <div style={{ paddingBottom: '1.5em', textAlign: 'justify', ...style }}>
      {children}
    </div>
  );
};

Block.defaultProps = {
  style: null,
  children: null,
};

export default Block;

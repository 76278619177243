import React, { ReactElement, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import BlogArticleCard from './BlogArticleCard';
import { blogs, BlogCategories, blogCategories } from './Blogs';

import './BlogOverview.scss';
import usePageTracking from '../../hooks/usePageTracking';

// UPDATE meta modified_time manually!

const BlogOverview = (): ReactElement => {
  const { pathname } = useLocation();
  let initCategory = null;
  if (pathname !== '/neubau-blog') {
    const categoryLink = pathname.split('/category/')[1].replace('/', '');
    initCategory = blogCategories.find((c) => c.link === categoryLink).id;
  }

  const [filteredCategory, setFilteredCategory] = useState(initCategory);

  usePageTracking('Neubau-Blog - Neubauprojekte in Wien');

  return (
    <>
      <Helmet>
        <title>Neubau-Blog - Neubauprojekte in Wien</title>
        <link
          rel="canonical"
          href="https://www.neubauprojekte.wien/neubau-blog/"
        />
        <meta
          name="description"
          content="Im Neubau-Blog finden Sie Ratgeber mit den wichtigsten Informationen im Zusammenhang mit dem Erwerb einer Neubauwohnung."
        />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Neubau-Blog - Neubauprojekte in Wien"
        />
        <meta
          property="og:description"
          content="Im Neubau-Blog finden Sie Ratgeber mit den wichtigsten Informationen im Zusammenhang mit dem Erwerb einer Neubauwohnung."
        />
        <meta
          property="og:url"
          content="https://www.neubauprojekte.wien/neubau-blog/"
        />
        <meta
          property="article:modified_time"
          content="2020-06-20T13:49:40+00:00"
        />
      </Helmet>
      <div className="container">
        <h1>Neubau-Blog</h1>
        <div className="separator" />
        <div className="p-grid">
          <div className="md:col-6">
            <p>
              Hier finden Sie die wichtigsten zusammengefassten Themen zu
              Neubauwohnungen.
            </p>
          </div>
        </div>
        <div className="spacer" />
        <div className="filterContainer">
          <div className="filter-list">
            <button
              type="button"
              className={`filter-list-item ${
                filteredCategory === null ? 'active' : ''
              }`}
              data-taxonomy="*"
              onClick={() => setFilteredCategory(null)}
            >
              <span>Alle</span>
            </button>
            <button
              type="button"
              className={`filter-list-item ${
                filteredCategory === BlogCategories.GefoerderteWohnungen
                  ? 'active'
                  : ''
              }`}
              data-taxonomy="gefoerderte-wohnung"
              data-amount="0"
              onClick={() =>
                setFilteredCategory(BlogCategories.GefoerderteWohnungen)
              }
            >
              <span>Geförderte Wohnungen</span>
            </button>
            <button
              type="button"
              className={`filter-list-item ${
                filteredCategory === BlogCategories.Immobilienmarkt
                  ? 'active'
                  : ''
              }`}
              data-taxonomy="immobilienmarkt"
              data-amount="1"
              onClick={() =>
                setFilteredCategory(BlogCategories.Immobilienmarkt)
              }
            >
              <span>Immobilienmarkt</span>
            </button>
            <button
              type="button"
              className={`filter-list-item ${
                filteredCategory === BlogCategories.RatgeberEigentumswohnung
                  ? 'active'
                  : ''
              }`}
              data-taxonomy="ratgeber-eigentumswohnung"
              data-amount="2"
              onClick={() =>
                setFilteredCategory(BlogCategories.RatgeberEigentumswohnung)
              }
            >
              <span>Ratgeber Eigentumswohnung</span>
            </button>
          </div>
        </div>
        <div className="p-grid">
          {blogs.map((blog) =>
            filteredCategory === null || filteredCategory === blog.category ? (
              <article key={blog.id} className="sm:col-6 blogCard">
                <BlogArticleCard blog={blog} />
              </article>
            ) : (
              <></>
            )
          )}
        </div>
      </div>
    </>
  );
};

export default BlogOverview;

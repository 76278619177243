import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactElement } from 'react';

import BlogArticleCard from '../BlogArticleCard';
import { BlogId, blogs } from '../Blogs';

import './Article.scss';
import SocialsBar from './SocialMediaCards';

// eslint-disable-next-line react/prop-types
const ListLink = ({ link, linkTitle }) => (
  <i>
    <span className="navItemArrow">
      <FontAwesomeIcon icon={faAngleRight} />
    </span>
    <a href={link}>{linkTitle}</a>
  </i>
);

interface ArticleSidebarProps {
  blogId: BlogId;
}

const ArticleSidebar = ({ blogId }: ArticleSidebarProps): ReactElement => (
  <div className="lg:col-4 sidebar">
    <h3>Weitere Neubau-Blog-Artikel</h3>
    <div className="p-grid cardGrid">
      {blogs.map(
        (blog) =>
          blog.id !== blogId && (
            <article className="sm:col-12 blogCard">
              <BlogArticleCard blog={blog} />
            </article>
          )
      )}
    </div>
    <div className="moreInfo">
      <h3 className="widgetTitle">Weitere Informationen</h3>
      <div>
        <ListLink link="/bautraeger-wien" linkTitle="Bauträger in Wien" />
        <br />
        <ListLink
          link="/genossenschaften"
          linkTitle="Genossenschaften in Wien"
        />
        <br />
        <ListLink link="/makler-wien" linkTitle="Immobilienmakler in Wien" />
        <br />
        <br />
        <h3 className="widgetTitle">Neubauprojekte</h3>
        <ListLink link="/" linkTitle="Alle Neubauprojekte anzeigen" />
        <br />
        <ListLink link="/merkliste" linkTitle="Gespeichterte Neubauprojekte" />
        <br />
      </div>
    </div>
    <SocialsBar />
  </div>
);

export default ArticleSidebar;

import React, { ReactElement } from 'react';
import { Helmet } from 'react-helmet';

import Block from '../../Layout/Block';
import { BlogCategories, BlogId } from '../Blogs';

import './Article.scss';
import ArticleBreadcrumbs from './ArticleBreadcrumbs';
import ArticleContents from './ArticleContents';
import ArticleSidebar from './ArticleSidebar';
import usePageTracking from '../../../hooks/usePageTracking';

// CHANGE meta modified_time manually!

const AltbauOderNeubauArticle = (): ReactElement => {
  const firstParagraph =
    'Altbau oder Neubau? – diese Frage hat sich vermutlich schon jeder einmal gestellt der den Entschluss eine Immobilie zu mieten oder zu kaufen. Denn beide Typen haben ihre Vor- und Nachteile, die im Einzelfall gegeneinander abgewogen werden sollten.';

  usePageTracking('Altbau oder Neubau? - Das sollten Sie unbedingt beachten!');

  return (
    <>
      <Helmet>
        <title>Altbau oder Neubau? - Das sollten Sie unbedingt beachten!</title>
        <meta name="description" content={firstParagraph} />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Altbau oder Neubau? - Das sollten Sie unbedingt beachten!"
        />
        <meta property="og:description" content={firstParagraph} />
        <meta
          property="og:url"
          content="https://www.neubauprojekte.wien/altbau-oder-neubau/"
        />
        <meta
          property="article:published_time"
          content="2020-08-13T07:00:50+00:00"
        />
        <meta
          property="article:modified_time"
          content="2020-08-16T14:20:20+00:00"
        />
      </Helmet>
      <div className="mainContent page article">
        <div className="p-grid">
          <div className="lg:col-8">
            <div className="article-content">
              <ArticleBreadcrumbs
                category={BlogCategories.RatgeberEigentumswohnung}
              />
              <h1 className="firstHeader" id="1">
                Altbau oder Neubau?
              </h1>
              <div className="separator" />
              <Block>{firstParagraph}</Block>
              <ArticleContents
                content={[
                  {
                    section: 'Altbau vs Neubau',
                    children: [
                      'Definition',
                      'Lage',
                      'Ausstattung',
                      'Anschaffungskosten',
                      'Erhaltungskosten',
                      'Wiederverkauf',
                      'Vermietung',
                    ],
                  },
                  'Altbau und Neubau im Vergleich',
                  'Fazit',
                ]}
              />
              <div className="separator" />
              <h2 id="2">Definition und Abgrenzung</h2>
              <Block>
                Rechtlich gesehen werden nur Gebäude, die vor dem Jahr 1953
                errichtet wurden als Altbau bezeichnet, doch viele Immobilien,
                die rechtlich Neubauten sind, weisen mit fortgeschrittenem Alter
                ähnliche Vor- und Nachteile wie klassische Altbauten auf. Um die
                Unterschiede innerhalb der Neubauimmobilien besser
                herausarbeiten zu können wollen wir hier zwischen
                Altbauimmobilien (Baujahr bis 1953), Bestandsimmobilien (Baujahr
                ab 1953, bis ca. 1990er) und Neubauimmobilien (insb. auch
                Erstbezugsimmobilien) unterschieden.
              </Block>
              <h2 id="3">Lage</h2>
              <Block>
                Da es vor allem in den inneren Bezirken kaum unbebaute
                Grundstücke zur Errichtung neuer Immobilien gibt, sind die
                innerstädtischen Bezirke geprägt von Altbauten und
                Bestandsimmobilien, während moderne Neubauten größtenteils in
                Außenbezirken errichtet werden. Zwei nennenswerte Ausnahmen sind
                die großen Bauprojekte am ehemaligen Nordbahnhof im zweiten
                Bezirk und in der Nähe von St. Marx im dritten Bezirk.
              </Block>
              <h2 id="4">Ausstattung</h2>
              <Block>
                Gut erhaltene Altbauwohnungen vermitteln durch ihre Raumhöhen
                von 3-4 m, meist langen, großen Fenster, Parkettböden und
                charakteristischen Flügeltüren ein sehr weiträumiges, edles
                Gefühl, auf das viele Immobilienkäufer nicht verzichten wollen.
                Leider führen diese hohen Räume und meist schlecht isolierten
                Fenster zu hohen Heizkosten und bieten kaum Schutz vor
                Straßenlärm. Bestandsimmobilien werden je nach Jahrgang mit
                deutlich energieeffizienteren Materialien gebaut und sind so
                größtenteils besser gedämmt. Die besten Ausstattungsmerkmale
                bieten jedoch ganz klar Neubauwohnungen. So muss sich der
                Eigentümer oder Mieter dank Zentralheizung beispielsweise nicht
                mehr um jährliche Thermenwartungen kümmern. Einige Wohnungen
                sind mit kontrollierten Wohnraumbelüftung, Fußbodenheizung oder
                eingebaute Klimaanlage ausgestattet. Auch die allgemeinen Räume
                des Hauses werden in Neubauten möglichst attraktiv gestaltet. In
                größeren Wohnprojekten der letzten Jahre sind eine moderne
                Waschküche, hauseigene Garage, Sauna, Heimkino oder
                Fitnesscenter keine Seltenheit.
              </Block>
              <h2 id="5">Anschaffungskosten</h2>
              <Block>
                Die üblicherweise modernere Ausstattung und freiere
                Vermietungsmöglichkeit die Neubauwohnungen bieten haben ihren
                Preis. Bei vergleichbarer Verkehrsanbindung und Ausstattung sind
                Neubauimmobilien oft bis zu 20% teurer als die günstigeren
                Altbau- oder Bestandsimmobilien. Insbesondere wer sich vor
                Sanierungsarbeiten nicht scheut kann bei sanierungsbedürftigen
                Altbau- oder Bestandswohnungen potenziell günstige Angebote
                finden.
              </Block>
              <h2 id="6">Erhaltungskosten</h2>
              <Block>
                Die höheren Anschaffungskosten von Neubauten können in den
                meisten Fällen durch ihre niedrigeren Erhaltungskosten, die
                Möglichkeit höhere Mieten zu vereinbaren und geringeren
                Reparaturbedarf aufgewogen werden. In Altbauten und älteren
                Bestandsimmobilien werden neben den üblichen Betriebskosten oft
                Sanierungsdarlehen abbezahlt oder es wird auf zukünftige
                Renovierungsarbeiten durch die Errichtung eines Reparaturfonds
                „angespart“ wodurch sich die laufenden Kosten stark erhöhen
                können.
              </Block>
              <h2 id="7">Wiederverkauf</h2>
              <Block>
                Tendenziell liegt der Wiederverkaufswert bei Neubauimmobilien
                höher als bei vergleichbaren Altbauwohnungen. Da im Vorhinein
                oft nicht klar ist wie sich Nachbarschaften in neu errichteten
                Stadtteilen entwickeln und welche Baumängel im Gebäude
                vorliegen, kann die längerfristige Entwicklung des
                Verkaufspreises bei Neubauwohnungen schwerer vorhergesagt
                werden.
              </Block>
              <h2 id="8">Vermietung</h2>
              <Block>
                <p>
                  Die größten Unterschiede zwischen Alt- und Neubau liegen
                  vermutlich in der Vermietung. Wohnungen in Gebäuden, die vor
                  1953 errichtet worden sind, unterliegen dem
                  Vollanwendungsbereich des Mietrechtsgesetzes (MRG) das
                  insbesondere eine gesetzlich bestimmte Mietzinsobergrenze
                  (exkl. Betriebskosten) vorsieht.Die Basis für die Berechnung
                  dieser Obergrenze bildet ein bundeslandspezifischer Richtwert
                  i.H.v. 5,81 €/m² für Wien der durch Zu- und Abschläge je nach
                  Lage, Beschaffenheit und Ausstattung der Wohnung angepasst
                  wird, jedoch in den meisten Fällen deutlich unter den
                  theoretisch am Markt erzielbaren Preisen bleibt. Wird die
                  Wohnung befristet vermietet muss die errechnete Obergrenze
                  noch einmal um 25% reduziert werden.
                </p>
                <p>
                  Generell ist die Position des Mieters im Vollanwendungsbereich
                  deutlich gestärkt, so gelten beispielsweise auch erschwerten
                  Kündigungsbedingungen im Falle eines unbefristeten
                  Mietvertrags.
                </p>
              </Block>
              <h2 id="9">Altbau und Neubau im Vergleich</h2>
              <span style={{ color: '#777777' }}>
                Tabelle 1 – Altbau vs. Neubau
              </span>
              <table>
                <tbody>
                  <tr>
                    <td width="20%">
                      <strong>Kategorie</strong>
                    </td>
                    <td width="40%">
                      <strong>Altbau</strong>
                    </td>
                    <td width="40%">
                      <strong>Neubau</strong>
                    </td>
                  </tr>
                  <tr>
                    <td width="20%">
                      <strong>Lage</strong>
                    </td>
                    <td width="40%">
                      Meist eher innerstädtisch, i.d.R. gut angebunden.
                    </td>
                    <td width="40%">
                      Größtenteils Außenbezirken, außer Neubauprojekte in 1020
                      und 1030.
                    </td>
                  </tr>
                  <tr>
                    <td width="20%">
                      <strong>Ausstattung</strong>
                    </td>
                    <td width="40%">
                      + Hohe Räume
                      <br />
                      + Große Fenster
                      <br />
                      + Hochwertige Böden- Schlechte Wärmedämmung
                      <br />
                      – Schlechte Schalldämmung
                      <br />
                      – Meist kleine Bäder
                      <br />– Kaum Freiflächen
                    </td>
                    <td width="40%">
                      + Gute Isolierung
                      <br />
                      + Moderne Belüftungsanlagen
                      <br />
                      + Moderne Heizungsanlagen
                      <br />+ Oft Gemeinschaftsräumeräume wie Fitnesscenter oder
                      Heimkino
                    </td>
                  </tr>
                  <tr>
                    <td width="20%">
                      <strong>Anschaffungskosten</strong>
                    </td>
                    <td width="40%">
                      I.d.R. niedriger, sehr günstige Preise, wenn
                      Sanierungsarbeiten in Kauf genommen werden.
                    </td>
                    <td width="40%">
                      Bis zu 20% höher als bei vergleichbaren Altbauimmobilien.
                    </td>
                  </tr>
                  <tr>
                    <td width="20%">
                      <strong>Erhaltung</strong>
                    </td>
                    <td width="40%">
                      Oft hohe laufende Kosten durch Aufstocken des
                      Reparaturfonds oder Abzahlung eines Sanierungsdarlehens
                      zusätzlich zu laufenden Betriebskosten
                    </td>
                    <td width="40%">
                      Meist niedrige Betriebskosten und kaum Aufwendungen für
                      Reparaturen oder Sanierungen.
                    </td>
                  </tr>
                  <tr>
                    <td width="20%">
                      <strong>Wiederverkauf</strong>
                    </td>
                    <td width="40%">
                      Etablierte Lagen bringen vorhersehbare
                      Wiederverkaufspreise, die aber meist unter den für
                      Neubauten erzielten Preisen liegen.
                    </td>
                    <td width="40%">
                      Neubauwohnung erzielen in der Regel hohe
                      Wiederverkaufspreise, Vorsicht ist bei neuen Stadtvierteln
                      geboten.
                    </td>
                  </tr>
                  <tr>
                    <td width="20%">
                      <strong>Vermietung</strong>
                    </td>
                    <td width="40%">
                      Mietzinsobergrenze und 25% Abschläge bei befristeten
                      Mietverträgen.
                    </td>
                    <td width="40%">
                      Keine Mietzinsobergrenze, durch gute Dämmung, moderne
                      Ausstattung und effiziente
                    </td>
                  </tr>
                </tbody>
              </table>
              <h2 id="10">Fazit: Altbau oder Neubau?</h2>
              <Block style={{ paddingBottom: '0.5em' }}>
                <p>
                  Handelt es sich beim Immobilienkauf um eine Anlegerwohnung,
                  die vermietet werden soll, ist man mit einer Neubauwohnung
                  finanziell besser abgesichert. Denn einerseits werden höhere
                  Mieteinkünfte durch bessere Ausstattung und die bloße
                  Teilanwendung des MRG erzielt und andererseits kann man in den
                  ersten Jahren mit niedrigeren laufenden Kosten rechnen.
                </p>
                <p>
                  Wer die Immobilie längerfristig zum Eigengebrauch nutzen will
                  und auf dem besonderen Flair einer Altbauwohnung oder
                  innerstädtische Lage nicht verzichten will, ist mit einem
                  generalsanierten(!) Altbau am besten beraten. In diesem Fall
                  ist darauf zu achten, dass es keine bestehenden (insbesondere
                  unbefristeten) Mietverhältnisse gibt, keine Sanierungsdarlehen
                  offen sind und der Reparaturfond im Idealfall gut gefüllt ist.
                </p>
              </Block>
              <div className="spacer" />
              <h3>Weiterführende Informationen – Stand 16.08.2020</h3>
              <div>
                <p>
                  Mietrechtsgesetz:
                  <br />
                  <a href="https://www.ris.bka.gv.at/GeltendeFassung.wxe?Abfrage=Bundesnormen&amp;Gesetzesnummer=10002531">
                    https://www.ris.bka.gv.at/GeltendeFassung.wxe?Abfrage=Bundesnormen&amp;Gesetzesnummer=10002531
                  </a>
                </p>
                <p>&nbsp;</p>
                <p>
                  Richtwertmietzins:
                  <br />
                  <a href="https://www.wien.gv.at/wohnen/wohnbautechnik/pdf/leitfaden-nutzflaeche-mnw.pdf">
                    https://www.wien.gv.at/wohnen/wohnbautechnik/pdf/leitfaden-nutzflaeche-mnw.pdf
                  </a>
                  <br />
                </p>
                <p>
                  <a href="https://kurier.at/wirtschaft/immobiz/wie-hoch-darf-die-miete-im-altbau-sein/716.765">
                    https://kurier.at/wirtschaft/immobiz/wie-hoch-darf-die-miete-im-altbau-sein/716.765
                  </a>
                </p>
                <p>&nbsp;</p>
                <p>
                  Preisunterschied Altbau/Neubau nach Bezirk:
                  <br />
                  <a href="https://www.immopreise.at/Wien/Wohnung/Miete/Altbau">
                    https://www.immopreise.at/Wien/Wohnung/Miete/Altbau
                  </a>
                </p>
                <p>&nbsp;</p>
                <p>
                  Sonstige:
                  <br />
                  <a href="https://kurier.at/wirtschaft/immobiz/altbau-vs-neubau-nicht-nur-eine-frage-des-geschmacks/21.411.708">
                    https://kurier.at/wirtschaft/immobiz/altbau-vs-neubau-nicht-nur-eine-frage-des-geschmacks/21.411.708
                  </a>
                </p>
              </div>
            </div>
          </div>
          <ArticleSidebar blogId={BlogId.AltbauNeubau} />
        </div>
      </div>
    </>
  );
};
export default AltbauOderNeubauArticle;
